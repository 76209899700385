const DepositDetails = () =>
  import(
    /* webpackChunkName: "DepositDetails" */ "@/components/dresskare/deposit/DepositDetails"
  );

export default [
  {
    path: "",
    name: "DepositDetails",
    component: DepositDetails,
  },
];
