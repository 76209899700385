<template>
  <VRow class="flex-nowrap justify-center justify-md-start mb-2">
    <VCol
      v-for="navButton in navigationItems"
      :key="navButton.routeName"
      class="flex-grow-0"
    >
      <VBtn
        class="customer-nav-button"
        :class="{
          'white--text': isActive(navButton),
          'primary--text': !isActive(navButton),
        }"
        :color="getButtonColor(navButton)"
        depressed
        @click="goToRouteName(navButton)"
      >
        <VIcon v-if="navButton.icon" class="d-inline-block d-xs-none mr-md-1">{{
          navButton.icon
        }}</VIcon>
        <span class="d-none d-md-inline">{{ navButton.title }}</span>
      </VBtn>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: "DresskareCustomNavButtons",
  props: {
    navigationItems: {
      type: Array,
      default: () => [],
    },
    exactMapping: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    isActive(navButton) {
      if (this.exactMapping && this.$route.name in this.exactMapping) {
        return navButton.routeName === this.exactMapping[this.$route.name];
      }
      return this.$route.name === navButton.routeName;
    },
    getButtonColor(navButton) {
      return this.isActive(navButton) ? "primary" : "white";
    },
    goToRouteName(navButton) {
      if (this.isActive(navButton)) return;
      this.$router.push({ name: navButton.routeName });
    },
  },
};
</script>

<style lang="scss">
.customer-nav-button {
  border: 1px solid $light-blue-1;
  box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 0.4);
}
</style>
