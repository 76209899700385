import i18n from "@/setup/i18n";
import store from "@/store";
import { Struct } from "@bufbuild/protobuf";
import { Model } from "@vuex-orm/core";
import Company from "./Company";
import RateSlide from "./RateSlide";
import Subscription from "./Subscription";

import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";

import { VendorController } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/vendor_connect";
import {
  VendorPartialUpdateRequest,
  VendorRequest,
} from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/vendor_pb";
const vendorClient = useDresskareGrpcClient(VendorController);

export default class Vendor extends Model {
  static entity = "vendor";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      keycloakUserUuid: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      firstName: this.string(),
      lastName: this.string(),
      email: this.string(),
      phoneNumber: this.string(),
      postalCode: this.string(),
      address: this.string(),
      city: this.string(),
      company: this.attr(null),
      companyObj: this.belongsTo(Company, "company", "uuid"),
      rateSlides: this.hasMany(RateSlide, "vendor"),
      negociatedPourcentageOnPrice: this.number(20),
      favoriteDeliveryPlace: this.string(null).nullable(),
      profilPicture: this.attr(null),
      favoriteAnnouncement: this.attr(null),
      enabled: this.boolean(true),
      deposits: this.attr(null),
      // rib: this.attr(null),
      identityCard: this.attr(null),
      vintedProfile: this.string(null).nullable(),
      acceptedLegalTermAt: this.string(null).nullable(),
      legalTermsUpdatedAt: this.string(),
      // company: this.hasOne(Company, "uuid")
      isSelfEmployed: this.boolean(false),
      receiveNewDepositStatus: this.string("AVAILABLE"),
      sponsorCode: this.string(null).nullable(),
      instagramOrWebsite: this.string(null).nullable(),
      preferedContactWay: this.string(null).nullable(),
      preferredSize: this.string(null).nullable(),
      subscription: this.hasOne(Subscription, "vendor_id"),
      productNumber: this.number(),
      depositGenderFavorite: this.string(null).nullable(),
      extensionFreeTrialStartDatetime: this.string(null).nullable(),
      connectors: this.attr([]),
      sumMonthlySelledPrice: this.number(0),
      depositNumber: this.number(0),
    };
  }

  static getAllFromProducts = async (products) => {
    // INFO - AM - 15/01/2023 - This is not working for deposit without vendor... Need to be refactored completely to get deposit with rateSlide and vendor directly in the serializer
    let depositsUuid = products.reduce((acc, product) => {
      acc.add(product.deposit);
      return acc;
    }, new Set());
    depositsUuid = Array.from(depositsUuid);

    depositsUuid = depositsUuid.reduce((acc, depositUuid) => {
      const vendorsWithSameDeposit = this.query()
        .where("deposits", (deposits) => {
          return deposits?.includes(depositUuid);
        })
        .get();
      if (!vendorsWithSameDeposit.length) {
        acc.add(depositUuid);
      }
      return acc;
    }, new Set());
    depositsUuid = Array.from(depositsUuid);
    if (!depositsUuid.length) {
      return;
    }
    return await this.getVendors({ deposits: Array.from(depositsUuid) }, true);
  };

  static getVendors = async (filters) => {
    try {
      const filtersStruct = filters ? Struct.fromJson(filters) : Struct();
      const response = await vendorClient.list({ Filters: filtersStruct });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static create = async (form) => {
    try {
      console.log("form", form);
      const response = await vendorClient.create(
        VendorRequest.fromJson(form, { ignoreUnknownFields: true }),
        {}
      );

      this.insertOrUpdate({ data: response });
      store.dispatch(
        "notifications/showSuccessNotification",
        i18n.t("successCreate")
      );
      return response;
    } catch (err) {
      console.error(err);
      if (err.message.includes("Vendor with this Email already exists")) {
        store.dispatch(
          "notifications/showErrorNotification",
          i18n.t("vendor.alreadyExist")
        );
      } else {
        store.dispatch(
          "notifications/showErrorNotification",
          i18n.t("vendor.errorCreate")
        );
      }
      return null;
    }
  };

  static getVendorByUuid = async (vendorUuid) => {
    const vendorFromStore = this.find(vendorUuid);

    // INFO - AM - 09/10/2022 - When retrieving deposit we get vendor first_name and last_name so the vendor exist in store but not fully
    if (vendorFromStore && vendorFromStore.email) {
      return vendorFromStore;
    } else {
      try {
        const response = await vendorClient.retrieve({ uuid: vendorUuid }, {});

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.error("error:", error);
        return null;
      }
    }
  };

  static getVendorByKeycloakUuid = async (keycloakUserUuid) => {
    const vendorFromStore = this.query()
      .where("keycloakUserUuid", keycloakUserUuid)
      .get();

    if (vendorFromStore.length) {
      return vendorFromStore[0];
    } else {
      try {
        const response = await vendorClient.retrieveByKeycloakUuid(
          { keycloakUserUuid: keycloakUserUuid },
          {}
        );

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.error("error:", error);
        return null;
      }
    }
  };

  static updateInDb = async (form) => {
    try {
      const response = await vendorClient.update(
        VendorRequest.fromJson(form, { ignoreUnknownFields: true }),
        {}
      );

      if (!response) {
        return null;
      }
      this.insertOrUpdate({ data: response });

      store.dispatch(
        "notifications/showSuccessNotification",
        i18n.t("successUpdate")
      );
      return response;
    } catch (err) {
      console.error(err);
      store.dispatch("notifications/showErrorNotification", i18n.t("error"));
      return null;
    }
  };

  static partialsUpdateInDb = async (form, partialsUpdateFields) => {
    try {
      form = {
        ...form,
        partialsUpdateFields,
      };
      const response = await vendorClient.partialUpdate(
        VendorPartialUpdateRequest.fromJson(form, {
          ignoreUnknownFields: true,
        }),
        {}
      );

      if (!response) {
        return null;
      }
      this.insertOrUpdate({ data: response });

      store.dispatch(
        "notifications/showSuccessNotification",
        i18n.t("successUpdate")
      );
      return response;
    } catch (err) {
      console.error(err);
      store.dispatch("notifications/showErrorNotification", i18n.t("error"));
      return null;
    }
  };

  static getVendorNameFromUuid = async (vendorUuid) => {
    const vendor = await this.getVendorByUuid(vendorUuid);
    return `${vendor.firstName} ${vendor.lastName}`;
  };
}

export const RECEIVE_NEW_DEPOSIT_STATUS = {
  AVAILABLE: "AVAILABLE",
  UNAVAILABLE: "UNAVAILABLE",
};

export const KNOW_DRESSKARE = {
  FACEBOOK: "FACEBOOK",
  INSTAGRAM: "INSTAGRAM",
  JOB_BOARD: "JOB_BOARD",
  VINTED: "VINTED",
  WORD_OF_MOUTH: "WORD_OF_MOUTH",
  DRESSKARE_WEBSITE: "DRESSKARE_WEBSITE",
  DRESSKARE_BLOG: "DRESSKARE_BLOG",
  SPONSORING: "SPONSORING",
};
export const PREFERED_CONTACT_WAY = {
  PHONE_CALL: "PHONE_CALL",
  EMAIL: "EMAIL",
  PHONE_MESSAGE: "PHONE_MESSAGE",
  WHATS_APP: "WHATS_APP",
};
