import dayjs from "dayjs";

export const isDifferentLists = (list1, list2) => {
  list1 = list1.sort((a, b) => a - b);
  list2 = list2.sort((a, b) => a - b);
  if (JSON.stringify(list1) !== JSON.stringify(list2)) {
    return true;
  }
  return false;
};

export const truncate = (text, stop, clamp) => {
  if (!text) {
    return "";
  }
  return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
};

export const formatDate = (value, activeHours = true, separator = "/") => {
  if (!value) {
    return;
  }
  if (value instanceof Date) {
    value = value.toISOString();
  }
  if (activeHours) {
    return dayjs(String(value)).format(
      `DD${separator}MM${separator}YYYY HH:mm`
    );
  } else {
    return dayjs(String(value)).format(`DD${separator}MM${separator}YYYY`);
  }
};
