import { Model } from "@vuex-orm/core";
import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";

import { BrandController } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_connect";
const brandClient = useDresskareGrpcClient(BrandController);

export default class Brand extends Model {
  static entity = "brand";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      name: this.string(""),
      slug: this.string(""),
      vintedId: this.string(""),
    };
  }

  static getBrands = async (filters) => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }

      const response = await brandClient.list({}, { headers: metadata });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static getBrandByUuid = async (brandUuid) => {
    const brandFromStore = this.find(brandUuid);

    if (brandFromStore) {
      return brandFromStore;
    } else {
      try {
        const response = await brandClient.retrieve({ uuid: brandUuid }, {});

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.error("error:", error);
        return null;
      }
    }
  };
}
