import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/setup/vuetify";
import setupSentry from "@/setup/sentry";
import DresskareLibrary from "@/dresskare-library/index";
import i18n from "@/setup/i18n";
import VueUploadComponent from "vue-upload-component";
import { initializeAuthHooks } from "@/setup/auth-hooks";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import { createHead } from "@unhead/vue";
import { UnheadPlugin } from "@unhead/vue/vue2";

import VueGtag from "vue-gtag";

const head = createHead();
Vue.use(UnheadPlugin);

Vue.use(
  VueGtag,
  {
    config: {
      // id: "GT-TQSS3ZRL",
      id: "GTM-M43BXC3K",
      // id: "G-2WREFD9DJC",
      params: {
        send_page_view: true,
      },
    },
    appName: "DresskareApp",
  },
  router
);

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.config.productionTip = false;

Vue.use(DresskareLibrary);
Vue.component("FileUpload", VueUploadComponent);

initializeAuthHooks();

if (process.env.VUE_APP_ENABLE_SENTRY === "true") {
  setupSentry(
    router,
    process.env.VUE_APP_SENTRY_DSN,
    process.env.VUE_APP_DRESSKARE_GRPC_API_ENV
  );
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  unhead: head,
  computed: {
    primaryColor() {
      return this.$vuetify.theme.themes.light.primary;
    },
    secondaryColor() {
      return this.$vuetify.theme.themes.light.secondary;
    },
    secondaryColorLight() {
      return "#ff5f85";
    },
  },
  render: (h) => h(App),
}).$mount("#app");
