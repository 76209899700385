<template>
  <div
    v-if="deferredPrompt && $vuetify.breakpoint.mobile"
    class="a2hs elevation-4"
  >
    <VBtn absolute right icon light @click="closeDialog">
      <VIcon>mdi-close</VIcon>
    </VBtn>
    <VContainer>
      <VRow no-gutters>
        <VCol cols="2" align-self="center">
          <img src="@/assets/images/favicon-32x32.png" />
        </VCol>
        <VCol align-self="center" cols="5">
          {{ $t("installBis", [appName]) }}
        </VCol>
        <VCol align-self="center">
          <VBtn ref="addBtn" small color="primary" @click="clickCallback">
            {{ $t("install") }}
          </VBtn>
        </VCol>
      </VRow>
    </VContainer>
  </div>
</template>

<script>
export default {
  name: "DresskareA2HS",
  data() {
    return {
      deferredPrompt: null,
      appName: process.env.VUE_APP_NAME,
    };
  },
  created() {
    this.captureEvent();
  },
  methods: {
    async clickCallback() {
      // Show the prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const choiceResult = await this.deferredPrompt.userChoice;
      if (choiceResult) {
        this.deferredPrompt = null;
      }
    },
    captureEvent() {
      window.addEventListener("beforeinstallprompt", (e) => {
        console.log(e);
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });
    },
    closeDialog() {
      this.deferredPrompt = null;
    },
  },
  i18n: {
    messages: {
      fr: {
        install: "Installer",
        installBis: "Installer '{0}' sur votre mobile",
      },
      en: {
        install: "Install",
        installBis: "Install '{0}' on your mobile",
      },
    },
  },
};
</script>

<style scoped lang="scss">
.a2hs {
  background-color: grey;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 99;
}
</style>
