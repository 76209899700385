<template>
  <div>
    <VRow
      v-for="(item, index) in internalValue"
      :key="index"
      justify="start"
      align="center"
    >
      <VCol cols="10">
        <slot
          name="visualizer"
          v-bind="{ vendorUuid: internalValue[index], index: index }"
        >
          <DresskareTextField v-model="internalValue[index]" v-bind="$attrs" />
        </slot>
      </VCol>
      <VCol v-if="index == internalValue.length - 1" cols="2">
        <VIcon :disabled="!item" color="primary" @click="internalValue.push('')"
          >mdi-plus-circle-outline</VIcon
        >
      </VCol>
      <VCol v-else cols="2">
        <VIcon color="red" @click="internalValue.splice(index, 1)"
          >mdi-minus-circle-outline</VIcon
        >
      </VCol>
    </VRow>
  </div>
</template>

<script>
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";

export default {
  name: "DresskareBaseArrayField",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      internalValue: [""],
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (isEqual(newValue, this.internalValue)) return;
        this.internalValue = cloneDeep(newValue);
        if (!this.internalValue.length) {
          this.internalValue.push("");
        }
      },
    },
    internalValue: {
      deep: true,
      handler(newValue) {
        let valueToEmit = cloneDeep(newValue);
        this.$emit("input", valueToEmit);
      },
    },
  },
};
</script>

<style>

</style>
