<template>
  <VDialog
    id="dialog"
    :value="value"
    :max-width="maxWidth"
    data-app="true"
    @input="$emit('input', $event)"
  >
    <VCard class="pb-3 pt-2">
      <VCardTitle v-if="title" class="pb-3 px-7" style="word-break: break-word">
        <p>{{ title }}</p>
      </VCardTitle>

      <VCardText
        class="px-7 mt-3"
        :class="{
          'subtitle-1': $vuetify.breakpoint.xlOnly,
          'body-1': $vuetify.breakpoint.lgAndDown,
        }"
      >
        <slot name="text">
          {{ text }}
        </slot>
      </VCardText>
      <VCardActions class="mt-8">
        <VRow :justify="$vuetify.breakpoint.mobile ? 'center' : 'end'">
          <VCol class="flex-grow-0">
            <VBtn text depressed class="mr-5 px-6" @click="emitCancel">
              {{ cancelText }}
            </VBtn>
          </VCol>
          <VCol class="flex-grow-0">
            <VBtn
              id="btnConfirmation"
              class="mr-5 px-6"
              :color="color"
              depressed
              :loading="validationBtnLoader"
              :disabled="disableConfirm"
              @click="$emit('validation')"
            >
              {{ confirmText }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: "DresskareBaseModalValidation",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    maxWidth: {
      type: String,
      default: "500px",
    },
    title: {
      type: String,
      default: function () {
        return this.$t("title");
      },
    },
    text: {
      type: String,
      default: function () {
        return this.$t("text");
      },
    },
    confirmText: {
      type: String,
      default: function () {
        return this.$t("confirmText");
      },
    },
    cancelText: {
      type: String,
      default: function () {
        return this.$t("cancelText");
      },
    },
    color: {
      type: String,
      default: "success",
    },
    disableConfirm: {
      type: Boolean,
      default: false,
    },
    validationBtnLoader: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitCancel() {
      this.$emit("input", false);
      this.$emit("cancel");
    },
  },
  i18n: {
    messages: {
      en: {
        Cancel: "Cancel",
        title: "Are you sure ?",
        text: "Mind your action before confirming",
        confirmText: "Confirm",
        cancelText: "Cancel",
      },
      fr: {
        Cancel: "Annuler",
        title: "Êtes vous sure ?",
        text: "Réfléchissez avant de confirmer",
        confirmText: "Confirmer",
        cancelText: "Annuler",
      },
    },
  },
};
</script>
