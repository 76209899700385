const state = () => ({
  lastNotification: null,
});

const getters = {
  getLastNotification: (state) => state.lastNotification,
};

const actions = {
  showSuccessNotification({ commit }, message) {
    commit("setLastNotification", {
      timeout: 3000,
      position: "center",
      color: "success",
      text: message,
    });
  },
  showErrorNotification({ commit }, message) {
    commit("setLastNotification", {
      timeout: 6000,
      position: "center",
      color: "error",
      text: message,
    });
  },
  showWarningNotification({ commit }, message) {
    commit("setLastNotification", {
      timeout: 3000,
      position: "center",
      color: "warning",
      text: message,
    });
  },
};

const mutations = {
  setLastNotification(state, notification) {
    state.lastNotification = notification;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
