import {
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_TYPE,
} from "@/models/Subscription.js";
import { ref } from "vue";

let displaySubscriptionModal = ref(false);
let subscriptionCancelAction = ref(null);

export default function () {
  const checkInErrorIfSubscriptionUpdateNeeded = (err, displayModal = true) => {
    try {
      // See https://connectrpc.com/docs/web/errors#error-messages
      const error_data = JSON.parse(err.rawMessage);
      if (error_data.code === "SUBSCRIPTION_UPDATE_NEEDED") {
        if (displayModal) {
          displaySubscriptionModal.value = true;
        }
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const isSubscriptionActive = (subscription) => {
    if (
      !subscription ||
      [SUBSCRIPTION_TYPE.COMMISSION, SUBSCRIPTION_TYPE.FORMATION].includes(
        subscription.type
      )
    ) {
      return false;
    }
    return [SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.TRIALING].includes(
      subscription.status
    );
  };

  const isSubscriptionIdExist = (subscription) => {
    return (
      subscription &&
      subscription.stripeId &&
      !subscription.stripeId.startsWith("formation")
    );
  };

  return {
    displaySubscriptionModal,
    subscriptionCancelAction,
    checkInErrorIfSubscriptionUpdateNeeded,
    isSubscriptionActive,
    isSubscriptionIdExist,
  };
}
