<template>
  <VContainer fluid>
    <VRow v-if="!$vuetify.breakpoint.mobile" no-gutters>
      <DresskareBaseNavigationLink
        v-for="tab in Object.keys(items)"
        :key="tab"
        :display-link="isDisplayed(tab)"
        :link-class="
          items[tab].navLinkClass ? items[tab].navLinkClass : navLinkClass
        "
        :icon="navLinkIcon"
        :exact="items[tab].exact"
        :text="items[tab].title"
        :route-name="items[tab].routeName"
        :params="items[tab].params"
        :append-icon="canDelete && items[tab].uuid ? 'mdi-close' : null"
        @click.native="goToRoute(tab)"
        @click:appendIcon="$emit('delete', items[tab].uuid)"
      />
    </VRow>

    <VRow v-else no-gutters>
      <DresskareBaseNavigationLink
        :display-link="true"
        :link-class="navLinkClassMobile"
        :icon="navLinkIcon"
        :text="currentTitle"
        :menus="menusResponsive"
        @click.native="goToRoute(tab)"
      />
    </VRow>

    <slot name="content">
      <VCard class="pa-0" style="border-top-left-radius: 0">
        <VRow no-gutters>
          <VCol class="pa-0">
            <VProgressCircular v-if="loading" indeterminate color="primary" />

            <RouterView
              v-else-if="routerViews"
              :value="value"
              @input="$emit('input', $event)"
            />
            <component
              :is="items[currentItem].component"
              v-else
              :value="value"
              :in-modal="true"
              v-bind="defaultComponentProps[currentItem]"
              @input="$emit('input', $event)"
            />
          </VCol>
        </VRow>
      </VCard>
    </slot>
  </VContainer>
</template>

<script>
import DresskareBaseNavigationLink from "./DresskareBaseNavigationLink.vue";

export default {
  name: "DresskareBaseTabCard",
  components: { DresskareBaseNavigationLink },
  props: {
    value: {
      type: null,
      default: null,
    },
    // Ex: { Affaires: { title: "Affaires", component: AffaireTab, display: true, params: {type: "aso"} } }
    items: {
      type: Object,
      required: true,
    },
    routerViews: {
      type: Boolean,
      default: true,
    },
    defaultTab: {
      type: String,
      default: "",
    },
    forceCurrentTabTo: {
      type: String,
      default: null,
    },
    defaultComponentProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    navLinkClass: {
      type: String,
      default: "tab-link",
    },
    navLinkClassMobile: {
      type: String,
      default: "menu-link",
    },
    navLinkIcon: {
      type: String,
      default: "",
    },
    navLinkExact: {
      type: Boolean,
      default: true,
    },
    // props for Loader
    loading: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTab: null,
    };
  },
  computed: {
    currentItem() {
      if (this.forceCurrentTabTo !== null) {
        // this is used when there is only one `routeName` with a route parameter in it
        return this.forceCurrentTabTo;
      }
      // Return current active tab based on URL
      if (this.routerViews) {
        const keys = Object.keys(this.items).filter((key) =>
          this.$route.name.startsWith(this.items[key].routeName)
        );
        if (keys.length > 0) {
          return keys.find((key) => {
            if (!this.items[key].params) {
              return this.$route.name === this.items[key].routeName;
            }
            return (
              this.$route.name === this.items[key].routeName &&
              JSON.stringify(this.$route.params) ===
                JSON.stringify(this.items[key].params)
            );
          });
        }
        return keys[0];
        // Return current tab based on default tab or last clicked tab
      } else {
        return this.currentTab ? this.currentTab : this.defaultTab;
      }
    },
    menusResponsive() {
      if (!this.$vuetify.breakpoint.mobile) {
        return null;
      }
      return Object.values(this.items);
    },
    currentTitle() {
      return this.items[this.currentItem]?.title;
    },
  },
  methods: {
    isDisplayed(tab) {
      return (
        typeof this.items[tab].display === "undefined" ||
        this.items[tab].display
      );
    },
    goToRoute(tab) {
      this.currentTab = tab;
    },
    routeParams(tab) {
      if (this.routerViews && this.items[tab].params) {
        return this.items[tab].params;
      }
      return {};
    },
  },
};
</script>

<style lang="scss">
.menu-restitued {
  color: white !important;
  background-color: #ff5f85 !important;
  &.active {
    color: white !important;
    background-color: red !important;
  }
  &.v-list-item--active {
    color: white !important;
    background-color: red !important;
  }
}
.tab-link {
  border-radius: 8px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: $third-color-light !important;
  color: $primary-color !important;
  font-weight: bold;
  margin-right: 10px;
  border-bottom: none !important;
  height: 40px;
  min-width: 150px;
  justify-content: center;
  font-size: 1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 16px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  &:hover,
  &.active {
    font-weight: bold;
    text-decoration: none;
    background-color: white !important;
  }
  &.restitued {
    color: white !important;
    background-color: #ff5f85 !important;
    &.active {
      color: white !important;
      background-color: red !important;
    }
  }
  &.general {
    color: white !important;
    background-color: $primary-color !important;
    &.active {
      color: $primary-color !important;
      background-color: white !important;
    }
  }
}
</style>
