import Vue from "vue";
import Vuetify, {
  VCard,
  VCardTitle,
  VCardActions,
  VDivider,
  VList,
  VListItem,
  VListItemTitle,
  VListItemIcon,
  VApp,
  VAppBar,
  VSlideItem,
  VSpacer,
  VMenu,
  VSlideGroup,
  VBtn,
  VIcon,
  VToolbarTitle,
  VSheet,
  VRow,
  VCol,
  VTooltip,
  VAvatar,
  VSelect,
  VAutocomplete,
  VCombobox,
  VTabsSlider,
} from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VApp,
    VCard,
    VCardActions,
    VCardTitle,
    VDivider,
    VList,
    VListItem,
    VListItemTitle,
    VAppBar,
    VSlideItem,
    VSpacer,
    VMenu,
    VSlideGroup,
    VBtn,
    VIcon,
    VToolbarTitle,
    VListItemIcon,
    VSheet,
    VRow,
    VCol,
    VTooltip,
    VAvatar,
    VSelect,
    VAutocomplete,
    VCombobox,
    VTabsSlider,
  },
  iconfont: "md",
});

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: "sm", // This is equivalent to a value of 960
  },
  theme: {
    themes: {
      light: {
        primary: "#4457ff",
        secondary: "#596aff",
        accent: "#8c9eff",
        error: "#b71c1c",
        background: "#4457ff",
        dresskarered: "#ff5f85",
      },
    },
  },
});
