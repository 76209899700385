<template>
  <VRow class="ma-0 pa-0">
    <VCol
      v-if="label && !useMaterials"
      cols="12"
      align="start"
      class="ma-0 pa-0"
    >
      <p class="font-weight-medium mb-0 text--primary--theme">
        {{ label }}
      </p>
    </VCol>
    <VCol cols="12" :class="{ 'pt-1': !useMaterials }" class="ma-0 pa-0">
      <VTextarea
        ref="vuetify-input"
        :label="useMaterials ? label : null"
        :placeholder="$attrs.placeholder ?? $t('yourText')"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>
      </VTextarea>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: "DresskareTextarea",
  props: {
    label: {
      type: String,
      default: "",
    },
    useMaterials: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$refs["input"] = this.$refs["vuetify-input"].$refs.input;
  },
};
</script>

<i18n>
  {
    "en": {
      "yourText": "Your text..."
    },
    "fr": {
      "yourText": "Votre texte..."
    }
  }
</i18n>
