<template>
  <div>
    <DresskareCombobox
      autocomplete="off"
      :items="items"
      :value="internalValue.address ? internalValue : null"
      item-value="label"
      item-text="label"
      return-object
      :class="veryDense"
      :no-filter="true"
      v-bind="$attrs"
      :rules="[(v) => !!v || $t('fieldRequired')]"
      @input="setInternalValue"
      @update:search-input="debounceSearchElements($event)"
    >
      <template #item="{ item }">
        {{ item.label }}
      </template>
    </DresskareCombobox>
  </div>
</template>

<script>
import axios from "axios";
import debounce from "lodash.debounce";
import { mapActions } from "vuex";

const apiGouvUrl = "https://api-adresse.data.gouv.fr/search/";

export default {
  name: "DresskareBaseGouvAddressCombobox",
  props: {
    // element can be regions, departements or communes
    value: {
      type: [String, Number],
      default: "",
    },
    extraParams: {
      type: Object,
      default: () => ({}),
    },
    minimumSearch: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      items: [],
      internalValue: { address: "", postalCode: "", city: "", label: "" },
      defaultExtraParams: { limit: 20, type: "housenumber" },
    };
  },
  computed: {
    veryDense() {
      return { "very-dense": this.$vuetify.breakpoint.xsOnly };
    },
    isDisabled() {
      return (
        typeof this.$attrs.disabled !== "undefined" &&
        (this.$attrs.disabled || this.$attrs.disabled === "")
      );
    },
  },
  watch: {
    internalValue(newVal) {
      this.$emit("input", newVal);
    },
    value() {
      if (
        !this.internalValue ||
        this.value === this.internalValue.address ||
        this.value === this.internalValue.label
      ) {
        return;
      }
      this.initializeData();
    },
  },
  async created() {
    this.initializeData();
  },
  methods: {
    ...mapActions("notifications", ["showWarningNotification"]),
    setInternalValue(event) {
      if (!event) {
        this.internalValue = {
          address: "",
          postalCode: "",
          city: "",
          label: "",
        };
        return;
      }
      if (typeof event === "string") {
        this.internalValue = {
          address: event,
          postalCode: "",
          city: "",
          label: event,
        };
        return;
      }
      this.internalValue = event;
    },
    initializeData() {
      if (this.value) {
        this.internalValue.address = this.value;
        this.internalValue.label = this.value;
      }
    },
    debounceSearchElements: debounce(async function (query) {
      // check first char is letter or number
      const regex = /^[a-zA-Z0-9]/;
      if (
        this.isDisabled ||
        !query ||
        query.trim().length < 3 ||
        !regex.test(query[0])
      ) {
        return;
      }
      this.$emit("search", query ?? "");
      if (query && this.minimumSearch && query.length < this.minimumSearch) {
        return;
      }
      if (!query) {
        return;
      }
      const responseDatas = await this.fetchElements({
        q: query,
      });
      this.items = this.transformResponse(responseDatas);
    }, 500),
    async fetchElements(params) {
      params = { ...params, ...this.defaultExtraParams, ...this.extraParams };
      const urlParams = new URLSearchParams(params).toString();
      try {
        const response = await axios.get(`${apiGouvUrl}?${urlParams}`);
        return response.data;
      } catch (error) {
        this.showWarningNotification(this.$t("errorFetchingGouvData"));
        console.error(error);
        return null;
      }
    },
    transformResponse(responseDatas) {
      if (!responseDatas) {
        return [];
      }
      return responseDatas.features.map((adressFeature) => {
        const properties = adressFeature.properties;
        return {
          name: properties.name,
          address: properties.name,
          postalCode: properties.postcode,
          city: properties.city,
          label: properties.label,
        };
      });
    },
  },
};
</script>


<i18n>
{
  "en": {
    "fieldRequired": "This field is required",
    "errorFetchingGouvData": "Error fetching data from gouv"
  },
  "fr": {
    "fieldRequired": "Ce champ est obligatoire",
    "errorFetchingGouvData": "Erreur lors de la récupération des addresses potentielles. Merci de réessayer."
  }
}
</i18n>
