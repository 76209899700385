/**
 * Auth hooks module used to fix oidc-auth accesstoken renewal
 * handle unintended disconnections and auto restore sessions
 */

import router from "@/router";
import store from "@/store";

export const redirectLogin = async () => {
  console.log("redirect login page");
  store.commit("oidc/unsetOidcAuth");
  await store.dispatch("oidc/authenticateOidc", {
    redirectPath: router.currentRoute.fullPath,
  });
};

export const tryRefreshSessionOrRedirectIfNotPublic = async () => {
  // we only care when the user switches to the page.
  if (router.currentRoute.meta.isPublic) {
    console.log("in public page no rediect");
    await store.dispatch("oidc/removeOidcUser");
    return;
  }
  try {
    await store.dispatch("oidc/authenticateOidcSilent");
  } catch (err) {
    console.log("refresh session or redirect: authenticate silent error", err);
    console.log("refresh session or redirect: authenticate silent error", err);
    await redirectLogin();
  }
};

export const initializeAuthHooks = () => {
  // Try to refresh token if expired and refreshing stopped
  // if fail => redirect to login page
  document.addEventListener("visibilitychange", async () => {
    if (document.hidden === false) {
      if (store.getters["oidc/oidcAccessToken"]) {
        return;
      }
      console.log(
        "Automatic silent renew stopped due to inactivity, restarting..."
      );
      await tryRefreshSessionOrRedirectIfNotPublic();
    }
  });
};
