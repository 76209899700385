<template>
  <div>
    <div v-if="!$vuetify.breakpoint.mobile || internalValue">
      <slot></slot>
    </div>

    <VRow
      v-if="$vuetify.breakpoint.mobile"
      class="text-center"
      @click="setInternalValue(!internalValue)"
    >
      <VCol cols="12" :style="`color: ${$root.primaryColor}`">
        <VIcon v-if="internalValue">mdi-chevron-up</VIcon>
        <VIcon v-else>mdi-chevron-down</VIcon>
        <b class="ml-1">{{ mobileActivatorText }}</b>
        <VDivider class="mt-2"></VDivider>
      </VCol>
    </VRow>
  </div>
</template>

<script>
export default {
  name: "DresskareBaseGroupResponsive",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    mobileActivatorText: {
      type: String,
      default: "Filter",
    },
  },
  data() {
    return {
      internalValue: false,
    };
  },
  created() {
    this.internalValue = this.value;
  },
  methods: {
    setInternalValue(newValue) {
      this.internalValue = newValue;
      this.$emit("input", newValue);
    },
  },
};
</script>
