const VendorDeposits = () =>
  import(
    /* webpackChunkName: "VendorDeposits" */ "@/components/dresskare/vendor/VendorDeposits"
  );
const DressingListView = () =>
  import(
    /* webpackChunkName: "DressingListView" */ "@/components/dresskare/vendor/DressingListView"
  );

const VendorDressing = () =>
  import(
    /* webpackChunkName: "VendorDressing" */ "@/components/dresskare/vendor/VendorDressing"
  );

const VendorDressingDeposit = () =>
  import(
    /* webpackChunkName: "VendorDressingDeposit" */ "@/components/dresskare/vendor/VendorDressingDeposit"
  );

const VendorInformations = () =>
  import(
    /* webpackChunkName: "VendorInformations" */ "@/components/dresskare/vendor/VendorInformations"
  );

const VendorCompany = () =>
  import(
    /* webpackChunkName: "VendorCompany" */ "@/components/dresskare/vendor/VendorCompany"
  );

const VendorInvoices = () =>
  import(
    /* webpackChunkName: "VendorInvoices" */ "@/components/dresskare/vendor/VendorInvoices"
  );

export default [
  {
    path: "",
    name: "VendorInformations",
    component: VendorInformations,
  },
  {
    path: "company",
    name: "VendorCompany",
    component: VendorCompany,
  },
  {
    path: "deposits",
    component: VendorDeposits,
    name: "VendorDeposits",
  },
  {
    path: "dressing",
    component: DressingListView,
    children: [
      {
        path: "",
        name: "VendorDressing",
        component: VendorDressing,
      },
      {
        path: ":depositUuid?",
        name: "VendorDressingDeposit",
        component: VendorDressingDeposit,
      },
    ],
  },
  {
    path: "invoices",
    name: "VendorInvoices",
    component: VendorInvoices,
  },
];
