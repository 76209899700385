<template>
  <Component
    :is="formComponent"
    ref="input"
    :items="options"
    :label="label"
    :value="value"
    :search-input.sync="search"
    :prepend-inner-icon="prependInnerIcon"
    :loading="loading || $attrs.loading"
    :no-data-text="noDataTextComputed"
    v-bind="$attrs"
    @input="emitInput"
    @focus="onFocus"
    @click="$emit('click', $event)"
    @change="$emit('change', $event)"
    @blur="emitBlur"
  >
    <template #item="{ item }">
      <slot name="custom-item" :item="item">
        <VListItemContent>
          <VListItemTitle v-if="itemProperty.length > 0">
            {{ item[itemProperty] }}</VListItemTitle
          >
          <VListItemTitle v-else>{{ getItemText(item) }}</VListItemTitle>
        </VListItemContent>
      </slot>
    </template>
    <template v-if="!combobox" #selection="{ item, index }">
      <slot name="selection" :item="item" :index="index">
        <div
          class="v-select__selection v-select__selection--comma"
          style="white-space: normal"
        >
          <span v-if="!value || (index === 0 && value.length > 1)">
            {{ getItemText(item, 9) }}</span
          >
          <span v-else-if="index === 0"> {{ getItemText(item, 12) }}</span>
          <span v-if="index === 1" class="grey--text caption"
            >(+{{ value.length - 1 }} {{ $t("Others") }})</span
          >
        </div>
      </slot>
    </template>
    <template v-if="displaySelectAll" #prepend-item>
      <VListItemGroup ripple @click="toggle">
        <VListItemIcon>
          <VIcon :color="value && value.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </VIcon>
        </VListItemIcon>
        <VListItemContent>
          <VListItemTitle>{{ $t("SelectAll") }}</VListItemTitle>
        </VListItemContent>
      </VListItemGroup>
      <VDivider class="mt-2" />
    </template>
  </Component>
</template>

<script>
import debounce from "lodash.debounce";
import { truncate } from "../../utils/helpers";

export default {
  name: "DresskareBaseFiltering",
  props: {
    value: {
      type: [Array, String, Object],
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    combobox: {
      type: Boolean,
      default: false,
    },
    displaySelectAll: {
      type: Boolean,
      default: false,
    },
    itemProperty: {
      type: String,
      default: "",
    },
    truncateText: {
      type: Boolean,
      default: true,
    },
    disableNullSearch: {
      type: Boolean,
      default: true,
    },
    searchCharMini: {
      type: Number,
      default: 0,
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    autoSelectFirst: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      search: null,
      loading: false,
      inputCalled: false,
    };
  },
  computed: {
    formComponent() {
      if (this.combobox) {
        return "DresskareCombobox";
      }
      return this.autocomplete ? "DresskareAutocomplete" : "DresskareSelect";
    },
    selectAll() {
      if (!this.value) {
        return false;
      }
      return this.value.length === this.options.length;
    },
    icon() {
      if (this.selectAll) {
        return "check_box";
      } else {
        return "check_box_outline_blank";
      }
    },
    noDataTextComputed() {
      if (this.loading || this.$attrs.loading) {
        return "Loading";
      }
      return this.noDataText || this.$t("noDataOrLoading");
    },
  },
  watch: {
    search(searchValue) {
      if (this.disableNullSearch && searchValue === null) {
        return;
      }
      if (
        this.searchCharMini > 0 &&
        (searchValue === null || searchValue.length < this.searchCharMini)
      ) {
        return;
      }
      // this.loading = true;
      this.$emit("searchStartLoading", true);
      this.search = searchValue;
      this.debounceSearch();
    },
    options() {
      this.loading = false;
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.$emit("input", []);
        } else {
          this.$emit(
            "input",
            this.options.map((option) => option.value)
          );
        }
      });
    },
    debounceSearch: debounce(function () {
      this.$emit("search", this.search);
    }, 500),
    truncate(...params) {
      return truncate(...params);
    },
    getItemText(item, truncateNumber) {
      let itemText = item && item.text ? item.text : item;
      if (this.prefix) {
        itemText = `${this.prefix} ${itemText}`;
      }
      if (this.truncateText && truncateNumber) {
        itemText = this.truncate(itemText, truncateNumber);
      }
      return itemText;
    },
    onFocus(event) {
      // INFO - AM - 10/04/2023 - Avoid menu to close directly after opened when user click because of focus.
      setTimeout(() => {
        if (!this.$refs.input.isMenuActive) {
          // INFO - AM - 10/08/2024 - This is because we have custom wrapper around vuetify input
          if (
            this.$refs.input.$refs &&
            this.$refs.input.$refs["vuetify-input"]
          ) {
            this.$refs.input.$refs["vuetify-input"].activateMenu();
          } else {
            this.$refs.input.activateMenu();
          }
        }
      }, 100);
      if (!this.options.length && !this.disableNullSearch) {
        this.loading = true;
      }
      this.$emit("focus", event);
    },
    selectFirstOptionNotHeader(options) {
      return options.find((option) => typeof option.header === "undefined");
    },
    emitInput($event) {
      // INFO - AM - 04/05/2023 - Need this to not always select first selection when autoSelectFirst is enabled. Need to check if $event is not null because event is call event if blur not call so it make issue
      if (this.autoSelectFirst && this.options.length && $event !== null) {
        this.inputCalled = true;
      }
      this.$emit("input", $event);
    },
    emitBlur($event) {
      // INFO - AM - 04/05/2023 - Need to auto select first option only if no input called. If called we want the input the user choose
      if (!this.inputCalled) {
        if (
          this.autoSelectFirst &&
          !this.$attrs.readonly &&
          this.options.length
        ) {
          this.$emit(
            "input",
            this.selectFirstOptionNotHeader(this.options).value
          );
        }
      } else {
        this.inputCalled = false;
      }
      this.$emit("blur", $event);
    },
  },

  // ----------------------- `i18n` option, setup locale info for component -----------------------
  i18n: {
    messages: {
      fr: {
        SelectAll: "Tout sélectionner",
        Others: "autres",
        noDataOrLoading: "Pas de donnée ou en cours de chargement",
      },
      en: {
        SelectAll: "Select All",
        Others: "others",
        noDataOrLoading: "No data available or loading",
      },
    },
  },
};
</script>
