const MyInfoVendor = () =>
  import(
    /* webpackChunkName: "MyInfoVendor" */ "@/components/dresskare/edit-profile/MyInfoVendor"
  );

const MyInfoCustomer = () =>
  import(
    /* webpackChunkName: "MyInfoCustomer" */ "@/components/dresskare/edit-profile/MyInfoCustomer"
  );

const MyInfoCompany = () =>
  import(
    /* webpackChunkName: "MyInfoCompany" */ "@/components/dresskare/edit-profile/MyInfoCompany"
  );

const MySubscription = () =>
  import(
    /* webpackChunkName: "MySubscription" */ "@/components/dresskare/edit-profile/MySubscription"
  );

const MyRatesVendor = () =>
  import(
    /* webpackChunkName: "MyRatesVendor" */ "@/components/dresskare/edit-profile/MyRatesVendor"
  );

const MyRatesCompany = () =>
  import(
    /* webpackChunkName: "MyRatesCompany" */ "@/components/dresskare/edit-profile/MyRatesCompany"
  );

const MyAnnouncements = () =>
  import(
    /* webpackChunkName: "MyAnnouncements" */ "@/components/dresskare/edit-profile/MyAnnouncements"
  );

export default [
  {
    path: "",
    name: "MyInfoVendor",
    component: MyInfoVendor,
  },
  {
    path: "customer",
    name: "MyInfoCustomer",
    component: MyInfoCustomer,
  },
  {
    path: "subscription",
    name: "MySubscription",
    component: MySubscription,
  },
  {
    path: "company",
    name: "MyInfoCompany",
    component: MyInfoCompany,
  },
  {
    path: "rates",
    name: "MyRatesVendor",
    component: MyRatesVendor,
  },
  {
    path: "rates-company",
    name: "MyRatesCompany",
    component: MyRatesCompany,
  },
  {
    path: "announcements",
    name: "MyAnnouncements",
    component: MyAnnouncements,
  },
];
