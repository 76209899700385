const Dashboard = () =>
  import(
    /* webpackChunkName: "Dashboard" */ "@/components/dresskare/Dashboard"
  );

const Customers = () =>
  import(
    /* webpackChunkName: "Customers" */ "@/components/dresskare/Customers"
  );

const Vendors = () =>
  import(/* webpackChunkName: "Vendors" */ "@/components/dresskare/Vendors");

const Customer = () =>
  import(/* webpackChunkName: "Customer" */ "@/components/dresskare/Customer");

const Vendor = () =>
  import(/* webpackChunkName: "Vendor" */ "@/components/dresskare/Vendor");

const DepositsAvailable = () =>
  import(
    /* webpackChunkName: "DepositsAvailable" */ "@/components/dresskare/DepositsAvailable"
  );

const Invoices = () =>
  import(/* webpackChunkName: "Invoices" */ "@/components/dresskare/Invoices");

const Messaging = () =>
  import(
    /* webpackChunkName: "Messaging" */ "@/components/dresskare/Messaging"
  );

const EditProfile = () =>
  import(
    /* webpackChunkName: "Messaging" */ "@/components/dresskare/EditProfile"
  );

const Deposit = () =>
  import(/* webpackChunkName: "Deposit" */ "@/components/dresskare/Deposit");

const Deposits = () =>
  import(/* webpackChunkName: "Deposits" */ "@/components/dresskare/Deposits");

const Inventory = () =>
  import(
    /* webpackChunkName: "Inventory" */ "@/components/dresskare/Inventory"
  );
const Stats = () =>
  import(/* webpackChunkName: "Stats" */ "@/components/dresskare/Stats");

const StatsNew = () =>
  import(/* webpackChunkName: "StatsNew" */ "@/components/dresskare/StatsNew");

import CustomerChildrens from "./customer/customerChildrens";
import VendorChildrens from "./vendor/vendorChildrens";
import EditProfileChildrens from "./edit-profile/editProfileChildrens";
import DepositChildrens from "./deposit/depositChildrens";
import StatsNewChildrens from "./stats-new/statsNewChildrens";

export default [
  {
    path: "",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "customers",
    name: "Customers",
    component: Customers,
    meta: {
      groupsAuthorized: ["Developer", "Vendor"],
    },
  },
  {
    path: "customer/:customerUuid",
    component: Customer,
    children: CustomerChildrens,
    meta: {
      groupsAuthorized: ["Developer", "Vendor"],
    },
  },
  {
    path: "vendors",
    name: "Vendors",
    component: Vendors,
    meta: {
      groupsAuthorized: ["Developer", "Admin"],
    },
  },
  {
    path: "vendor/:vendorUuid",
    component: Vendor,
    children: VendorChildrens,
    meta: {
      groupsAuthorized: ["Developer", "Admin"],
    },
  },
  {
    path: "deposits",
    name: "Deposits",
    component: Deposits,
  },
  {
    path: "deposits-available",
    name: "DepositsAvailable",
    component: DepositsAvailable,
    meta: {
      groupsAuthorized: ["Developer", "Admin", "Vendor"],
    },
  },
  {
    path: "deposit/:depositUuid",
    component: Deposit,
    children: DepositChildrens,
    meta: {
      groupsAuthorized: ["Developer", "Admin", "Vendor"],
    },
  },
  {
    path: "inventory",
    name: "Inventory",
    component: Inventory,
    meta: {
      groupsAuthorized: ["Developer", "Admin", "Vendor"],
    },
  },
  {
    path: "stats",
    name: "Stats",
    component: Stats,
    meta: {
      groupsAuthorized: ["Developer", "Admin"],
    },
  },
  {
    path: "stats-new",
    component: StatsNew,
    children: StatsNewChildrens,
    meta: {
      groupsAuthorized: ["Developer", "Admin"],
    },
  },
  {
    path: "invoices",
    name: "Invoices",
    component: Invoices,
  },
  {
    path: "messaging",
    name: "Messaging",
    component: Messaging,
  },
  {
    path: "edit-profile",
    component: EditProfile,
    children: EditProfileChildrens,
  },
];
