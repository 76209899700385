<template>
  <VContainer fill-height fluid class="pa-0 ma-0">
    <VRow no-gutters :style="`min-height: calc(100vh - ${borderTop}px)`">
      <VCol cols="12" md="6" justify="center" align="center">
        <img
          v-if="displayLeftLogo"
          src="@/assets/images/dresskare-logo.png"
          style="position: absolute; top: 10px; left: 10px"
          width="200px"
          alt="Dresskare logo"
        />
        <VRow
          :style="getSlotRowStyle()"
          no-gutters
          justify="center"
          align="center"
        >
          <VCol
            v-if="$vuetify.breakpoint.mobile && !hideMobileImg"
            cols="12"
            :style="`background-color: ${$root.primaryColor};`"
            class="mb-8"
          >
            <img
              :src="mapImageToBg[validationImage]"
              style="max-height: 200px; max-width: 200px"
              alt="validation"
            />
          </VCol>
          <slot></slot>
        </VRow>
      </VCol>
      <VCol
        v-if="!$vuetify.breakpoint.mobile"
        cols="6"
        align="center"
        justify="center"
        fill-height
        :style="`background-color: ${$root.primaryColor};`"
      >
        <div class="cover-bg" :style="bgImageDynamic"></div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import BgDresskare from "@/assets/images/dresskare-logo-square.png";
import BgThankYou from "@/assets/images/thank-you.jpg";

export default {
  name: "DresskareBasePublicConfirmation",
  props: {
    validationImage: {
      type: String,
      default: "dresskare",
    },
    displayLeftLogo: {
      type: Boolean,
      default: false,
    },
    borderTop: {
      type: Number,
      default: 0,
    },
    slotRowStyle: {
      type: Object,
      default: () => ({ "max-width": "500px" }),
    },
    hideMobileImg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mapImageToBg: {
        thankyou: BgThankYou,
        dresskare: BgDresskare,
      },
    };
  },
  computed: {
    bgImageDynamic() {
      return {
        backgroundImage: `url(${this.mapImageToBg[this.validationImage]})`,
        height: `calc(100% - ${this.borderTop}px)`,
      };
    },
  },
  methods: {
    getSlotRowStyle() {
      return this.$vuetify.breakpoint.mobile
        ? this.slotRowStyle
        : { ...this.slotRowStyle, height: "100%" };
    },
  },
};
</script>

<style>
.cover-bg {
  width: 100%;
  background-size: 50%;
  background-position: 100% 50%;
  background-attachment: fixed;
}
</style>
