// @generated by protoc-gen-es v1.7.2 with parameter "target=js+dts"
// @generated from file dresskare_back/invoice.proto (package dresskareback.invoice, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Struct } from "@bufbuild/protobuf";

/**
 * @generated from message dresskareback.invoice.CompanyResponse
 */
export const CompanyResponse = proto3.makeMessageType(
  "dresskareback.invoice.CompanyResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "siret", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "proof_registration", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "website_link", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "tax_eligible", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 7, name: "negociated_pourcentage_on_price", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.invoice.CustomerForInvoiceResponse
 */
export const CustomerForInvoiceResponse = proto3.makeMessageType(
  "dresskareback.invoice.CustomerForInvoiceResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.invoice.GenerateInvoicesRequest
 */
export const GenerateInvoicesRequest = proto3.makeMessageType(
  "dresskareback.invoice.GenerateInvoicesRequest",
  () => [
    { no: 1, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "customer_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "vendor_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.invoice.InvoiceListRequest
 */
export const InvoiceListRequest = proto3.makeMessageType(
  "dresskareback.invoice.InvoiceListRequest",
  () => [
    { no: 1, name: "_filters", kind: "message", T: Struct, opt: true },
    { no: 2, name: "_pagination", kind: "message", T: Struct, opt: true },
  ],
);

/**
 * @generated from message dresskareback.invoice.InvoiceListResponse
 */
export const InvoiceListResponse = proto3.makeMessageType(
  "dresskareback.invoice.InvoiceListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: InvoiceResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.invoice.InvoiceResponse
 */
export const InvoiceResponse = proto3.makeMessageType(
  "dresskareback.invoice.InvoiceResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "total_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "customer", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "customer_obj", kind: "message", T: SmallCustomerResponse, opt: true },
    { no: 9, name: "vendor_obj", kind: "message", T: SmallVendorResponse, opt: true },
    { no: 10, name: "due_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "full_invoice_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "pdf_file", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "invoice_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "invoice_payed_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "stripe_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "stripe_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "stripe_pdf_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "stripe_hosted_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "stripe_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.invoice.InvoiceRetrieveRequest
 */
export const InvoiceRetrieveRequest = proto3.makeMessageType(
  "dresskareback.invoice.InvoiceRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.invoice.InvoiceUpdateInvoiceStatusRequest
 */
export const InvoiceUpdateInvoiceStatusRequest = proto3.makeMessageType(
  "dresskareback.invoice.InvoiceUpdateInvoiceStatusRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "invoice_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.invoice.InvoiceWithExtraDataListResponse
 */
export const InvoiceWithExtraDataListResponse = proto3.makeMessageType(
  "dresskareback.invoice.InvoiceWithExtraDataListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: InvoiceWithExtraDataResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.invoice.InvoiceWithExtraDataResponse
 */
export const InvoiceWithExtraDataResponse = proto3.makeMessageType(
  "dresskareback.invoice.InvoiceWithExtraDataResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "total_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "customer", kind: "message", T: CustomerForInvoiceResponse },
    { no: 7, name: "vendor", kind: "message", T: VendorForInvoiceResponse },
    { no: 8, name: "customer_obj", kind: "message", T: SmallCustomerResponse, opt: true },
    { no: 9, name: "vendor_obj", kind: "message", T: SmallVendorResponse, opt: true },
    { no: 10, name: "due_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "full_invoice_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "pdf_file", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "invoice_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "invoice_payed_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "stripe_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "stripe_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "stripe_pdf_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "stripe_hosted_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "stripe_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "extra_data", kind: "message", T: Struct, opt: true },
    { no: 22, name: "total_price_ht", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 23, name: "tax", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 24, name: "tax_eligible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "tax_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message dresskareback.invoice.SmallCustomerResponse
 */
export const SmallCustomerResponse = proto3.makeMessageType(
  "dresskareback.invoice.SmallCustomerResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "is_sourced_by_dresskare", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.invoice.SmallVendorResponse
 */
export const SmallVendorResponse = proto3.makeMessageType(
  "dresskareback.invoice.SmallVendorResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "vinted_profile", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.invoice.VendorForInvoiceResponse
 */
export const VendorForInvoiceResponse = proto3.makeMessageType(
  "dresskareback.invoice.VendorForInvoiceResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "company", kind: "message", T: CompanyResponse },
    { no: 10, name: "negociated_pourcentage_on_price", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

