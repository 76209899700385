<template>
  <div class="eventSnackbar">
    <VSnackbar
      v-for="(snackbarElement, index) in snackbarElements"
      ref="snackbars"
      :key="index"
      :value="snackbarElement.display"
      :timeout="snackbarElement.timeout"
      :multi-line="true"
      :style="{ top: arrayOfTop[index] }"
      :color="snackbarElement.color"
      v-bind="{ [position(snackbarElement.position)]: true }"
      top
      @input="$emit('snackbarElements', snackbarElements)"
    >
      <VRow
        no-gutters
        align="center"
        justify="space-between"
        @click="notifAction(snackbarElement)"
      >
        <VCol cols="9">
          <h2
            v-if="snackbarElement.title"
            class="white--text ma-0"
            style="font-size: 16px"
          >
            {{
              $te(snackbarElement.title)
                ? $t(snackbarElement.title)
                : snackbarElement.title
            }}
          </h2>
          <p class="white--text ma-0">
            {{
              $te(snackbarElement.text)
                ? $t(snackbarElement.text)
                : snackbarElement.text
            }}
          </p>
        </VCol>
        <VCol cols="3">
          <VRow justify="end">
            <VBtn dark text @click="closeSnackbar(index)">
              {{ $t("close") }}
            </VBtn>
          </VRow>
        </VCol>
      </VRow>
    </VSnackbar>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import isEqual from "lodash/isEqual";

export default {
  name: "DresskareBaseNotification",
  data() {
    return {
      /* List of object representing vuetify snackbar element.
       * keys: display, timeout, color, title, text
       * */
      snackbarElements: [],
      // Array used to specified the top value of snackbar
      arrayOfTop: ["30px"],
    };
  },
  computed: {
    ...mapGetters("notifications", ["getLastNotification"]),
  },
  watch: {
    //Recalculate the top when a snackbar is added or deleted
    snackbarElements() {
      this.arrayOfTop = ["30px"];

      if (!this.$refs.snackbars) {
        return;
      }

      this.$refs.snackbars.forEach((snackbar) => {
        const rect = snackbar.$el.getBoundingClientRect();
        this.arrayOfTop.push(`${rect.top + snackbar.$el.clientHeight}px`);
      });
    },
    //Listen for a new notification
    getLastNotification(lastNotification) {
      if (!lastNotification) {
        return;
      }

      const snackbarElementToAdd = {
        ...lastNotification,
        display: true,
        timeout: -1,
      };

      this.setLastNotification(null);

      this.snackbarElements.push(snackbarElementToAdd);
      this.$emit("snackbarElements", this.snackbarElements);

      // Dont delete notification if infinite timeout
      if (lastNotification.timeout !== -1) {
        setTimeout(() => {
          this.findAndDeleteSnackbarElement(snackbarElementToAdd);
        }, lastNotification.timeout || 6000);
      }
    },
  },
  methods: {
    ...mapMutations("notifications", ["setLastNotification"]),
    findAndDeleteSnackbarElement(snackbarElement) {
      for (let i = 0; i < this.snackbarElements.length; i++) {
        if (isEqual(this.snackbarElements[i], snackbarElement)) {
          this.snackbarElements.splice(i, 1);
          break;
        }
      }
      this.$emit("snackbarElements", this.snackbarElements);
    },
    closeSnackbar(index) {
      this.snackbarElements.splice(index, 1);
      this.$emit("snackbarElements", this.snackbarElements);
    },
    position(position) {
      if (position === "left" || position === "center") {
        return position;
      } else {
        return "right";
      }
    },
    notifAction(snackbarElement) {
      if (snackbarElement.action) {
        snackbarElement.action();
      }
    },
  },

  // ----------------------- `i18n` option, setup locale info for component -----------------------
  i18n: {
    messages: {
      fr: {
        close: "Fermer",
      },
      en: {
        close: "Close",
      },
    },
  },
};
</script>
