const NewDepositContact = () =>
  import(
    /* webpackChunkName: "NewDepositContact" */ "@/components/new-deposit/NewDepositContact"
  );

const NewDepositInfo = () =>
  import(
    /* webpackChunkName: "NewDepositInfo" */ "@/components/new-deposit/NewDepositInfo"
  );

export default [
  {
    path: ":customerUuid?",
    name: "NewDepositContact",
    component: NewDepositContact,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "deposit-info/:customerUuid",
    name: "NewDepositInfo",
    component: NewDepositInfo,
    meta: {
      isPublic: true,
    },
  },
];
