// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=js+dts"
// @generated from file dresskare_back/product.proto (package dresskareback.product, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BrandListRequest, BrandListResponse, BrandResponse, BrandRetrieveRequest, ColorListRequest, ColorListResponse, ColorResponse, ColorRetrieveRequest, DepositDestroyRequest, DepositGetAdminNotesRequest, DepositGetAdminNotesResponse, DepositGetDraftRequest, DepositGetFilledProductNumberRequest, DepositGetFilledProductNumberResponse, DepositGetRecommendedPriceRequest, DepositGetRecommendedPriceResponse, DepositImageAssignDepositListRequest, DepositImageDestroyRequest, DepositImageListRequest, DepositImageListResponse, DepositImagePartialUpdateRequest, DepositImageRequest, DepositImageResponse, DepositImageRetrieveRequest, DepositImportDataResponse, DepositPartialUpdateRequest, DepositRequest, DepositResponse, DepositRetrieveRequest, DepositsStatsForFiltersResponse, DepositUpdateAdminNotesRequest, DepositWithStatsListRequest, DepositWithStatsListResponse, ImportSheetRequest, ItemsToFindPriceProductRequest, PriceForComparableProductResponse, ProductCategoryListResponse, ProductCategoryResponse, ProductCategoryRetrieveRequest, ProductDashboardListResponse, ProductDataRecognizedResponse, ProductDestroyRequest, ProductExportListResponse, ProductImageDestroyRequest, ProductImageListRequest, ProductImageListResponse, ProductImagePartialUpdateRequest, ProductImageRequest, ProductImageResponse, ProductImageRetrieveRequest, ProductLaunchProductRecognitionRequest, ProductListRequest, ProductListResponse, ProductPartialUpdateRequest, ProductRequest, ProductResponse, ProductRetrieveRequest, ProductSizeListRequest, ProductSizeListResponse, ProductSizeResponse, ProductSizeRetrieveRequest } from "./product_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service dresskareback.product.BrandController
 */
export const BrandController = {
  typeName: "dresskareback.product.BrandController",
  methods: {
    /**
     * @generated from rpc dresskareback.product.BrandController.List
     */
    list: {
      name: "List",
      I: BrandListRequest,
      O: BrandListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.BrandController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: BrandRetrieveRequest,
      O: BrandResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service dresskareback.product.ColorController
 */
export const ColorController = {
  typeName: "dresskareback.product.ColorController",
  methods: {
    /**
     * @generated from rpc dresskareback.product.ColorController.List
     */
    list: {
      name: "List",
      I: ColorListRequest,
      O: ColorListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ColorController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: ColorRetrieveRequest,
      O: ColorResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service dresskareback.product.DepositController
 */
export const DepositController = {
  typeName: "dresskareback.product.DepositController",
  methods: {
    /**
     * @generated from rpc dresskareback.product.DepositController.Create
     */
    create: {
      name: "Create",
      I: DepositRequest,
      O: DepositResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositController.CreateOrUpdateDraft
     */
    createOrUpdateDraft: {
      name: "CreateOrUpdateDraft",
      I: DepositRequest,
      O: DepositResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositController.Destroy
     */
    destroy: {
      name: "Destroy",
      I: DepositDestroyRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositController.GetAdminNotes
     */
    getAdminNotes: {
      name: "GetAdminNotes",
      I: DepositGetAdminNotesRequest,
      O: DepositGetAdminNotesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositController.GetDraft
     */
    getDraft: {
      name: "GetDraft",
      I: DepositGetDraftRequest,
      O: DepositResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositController.GetFilledProductNumber
     */
    getFilledProductNumber: {
      name: "GetFilledProductNumber",
      I: DepositGetFilledProductNumberRequest,
      O: DepositGetFilledProductNumberResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositController.GetRecommendedPrice
     */
    getRecommendedPrice: {
      name: "GetRecommendedPrice",
      I: DepositGetRecommendedPriceRequest,
      O: DepositGetRecommendedPriceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositController.GetStatsForFilters
     */
    getStatsForFilters: {
      name: "GetStatsForFilters",
      I: Empty,
      O: DepositsStatsForFiltersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositController.ImportData
     */
    importData: {
      name: "ImportData",
      I: ImportSheetRequest,
      O: DepositImportDataResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositController.List
     */
    list: {
      name: "List",
      I: DepositWithStatsListRequest,
      O: DepositWithStatsListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositController.PartialUpdate
     */
    partialUpdate: {
      name: "PartialUpdate",
      I: DepositPartialUpdateRequest,
      O: DepositResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: DepositRetrieveRequest,
      O: DepositResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositController.Update
     */
    update: {
      name: "Update",
      I: DepositRequest,
      O: DepositResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositController.UpdateAdminNotes
     */
    updateAdminNotes: {
      name: "UpdateAdminNotes",
      I: DepositUpdateAdminNotesRequest,
      O: DepositResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service dresskareback.product.DepositImageController
 */
export const DepositImageController = {
  typeName: "dresskareback.product.DepositImageController",
  methods: {
    /**
     * @generated from rpc dresskareback.product.DepositImageController.AssignDeposit
     */
    assignDeposit: {
      name: "AssignDeposit",
      I: DepositImageAssignDepositListRequest,
      O: DepositImageListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositImageController.Create
     */
    create: {
      name: "Create",
      I: DepositImageRequest,
      O: DepositImageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositImageController.Destroy
     */
    destroy: {
      name: "Destroy",
      I: DepositImageDestroyRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositImageController.List
     */
    list: {
      name: "List",
      I: DepositImageListRequest,
      O: DepositImageListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositImageController.PartialUpdate
     */
    partialUpdate: {
      name: "PartialUpdate",
      I: DepositImagePartialUpdateRequest,
      O: DepositImageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositImageController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: DepositImageRetrieveRequest,
      O: DepositImageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.DepositImageController.Update
     */
    update: {
      name: "Update",
      I: DepositImageRequest,
      O: DepositImageResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service dresskareback.product.ProductCategoryController
 */
export const ProductCategoryController = {
  typeName: "dresskareback.product.ProductCategoryController",
  methods: {
    /**
     * @generated from rpc dresskareback.product.ProductCategoryController.List
     */
    list: {
      name: "List",
      I: Empty,
      O: ProductCategoryListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductCategoryController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: ProductCategoryRetrieveRequest,
      O: ProductCategoryResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service dresskareback.product.ProductController
 */
export const ProductController = {
  typeName: "dresskareback.product.ProductController",
  methods: {
    /**
     * @generated from rpc dresskareback.product.ProductController.Create
     */
    create: {
      name: "Create",
      I: ProductRequest,
      O: ProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductController.Destroy
     */
    destroy: {
      name: "Destroy",
      I: ProductDestroyRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductController.ExportProducts
     */
    exportProducts: {
      name: "ExportProducts",
      I: Empty,
      O: ProductExportListResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc dresskareback.product.ProductController.GetPriceSimilarArticle
     */
    getPriceSimilarArticle: {
      name: "GetPriceSimilarArticle",
      I: ItemsToFindPriceProductRequest,
      O: PriceForComparableProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductController.LaunchProductRecognition
     */
    launchProductRecognition: {
      name: "LaunchProductRecognition",
      I: ProductLaunchProductRecognitionRequest,
      O: ProductDataRecognizedResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductController.List
     */
    list: {
      name: "List",
      I: ProductListRequest,
      O: ProductListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductController.ListProductDashboard
     */
    listProductDashboard: {
      name: "ListProductDashboard",
      I: Empty,
      O: ProductDashboardListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductController.PartialUpdate
     */
    partialUpdate: {
      name: "PartialUpdate",
      I: ProductPartialUpdateRequest,
      O: ProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: ProductRetrieveRequest,
      O: ProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductController.Update
     */
    update: {
      name: "Update",
      I: ProductRequest,
      O: ProductResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service dresskareback.product.ProductImageController
 */
export const ProductImageController = {
  typeName: "dresskareback.product.ProductImageController",
  methods: {
    /**
     * @generated from rpc dresskareback.product.ProductImageController.Create
     */
    create: {
      name: "Create",
      I: ProductImageRequest,
      O: ProductImageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductImageController.Destroy
     */
    destroy: {
      name: "Destroy",
      I: ProductImageDestroyRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductImageController.List
     */
    list: {
      name: "List",
      I: ProductImageListRequest,
      O: ProductImageListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductImageController.PartialUpdate
     */
    partialUpdate: {
      name: "PartialUpdate",
      I: ProductImagePartialUpdateRequest,
      O: ProductImageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductImageController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: ProductImageRetrieveRequest,
      O: ProductImageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductImageController.Update
     */
    update: {
      name: "Update",
      I: ProductImageRequest,
      O: ProductImageResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service dresskareback.product.ProductSizeController
 */
export const ProductSizeController = {
  typeName: "dresskareback.product.ProductSizeController",
  methods: {
    /**
     * @generated from rpc dresskareback.product.ProductSizeController.List
     */
    list: {
      name: "List",
      I: ProductSizeListRequest,
      O: ProductSizeListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.product.ProductSizeController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: ProductSizeRetrieveRequest,
      O: ProductSizeResponse,
      kind: MethodKind.Unary,
    },
  }
};

