<template>
  <VMenu v-if="displayLink && menus.length" open-on-hover offset-y>
    <template #activator="{ on, attrs }">
      <span :class="linkClass" class="menus-btn" v-bind="attrs" v-on="on">
        {{ text }}
        <VIcon right size="16" color="#333333" class="pl-2 ma-0">
          mdi-menu-down
        </VIcon>
      </span>
    </template>

    <VList class="pa-0">
      <VListItem
        v-for="(item, index) in menus"
        :key="index"
        :to="{ name: item.routeName, params: item.params }"
        :exact="exact"
        :exact-path="exactPath"
        :class="item.navLinkClassMobile"
      >
        <VIcon v-if="icon" size="20" class="mr-1">
          {{ icon }}
        </VIcon>
        {{ item.title }}
      </VListItem>
    </VList>
  </VMenu>
  <RouterLink
    v-else-if="displayLink"
    :to="{ name: routeName, params }"
    active-class="active"
    :class="linkClass"
    :exact="exact"
    :exact-path="exactPath"
  >
    <VIcon v-if="icon" size="20" class="mr-1">
      {{ icon }}
    </VIcon>
    {{ text }}
    <VSpacer v-if="appendIcon" />
    <VIcon
      v-if="appendIcon"
      color="primary"
      small
      class="mr-1"
      @click.stop.prevent="$emit('click:appendIcon')"
    >
      {{ appendIcon }}
    </VIcon>
  </RouterLink>
</template>
<script>
export default {
  name: "DresskareBaseNavigationLink",
  props: {
    linkClass: {
      type: String,
      default: "navbar-item-link",
    },
    text: {
      type: String,
      default: "",
    },
    routeName: {
      type: String,
      default: "",
    },
    displayLink: {
      type: Boolean,
      default: false,
    },
    exact: {
      type: Boolean,
      default: true,
    },
    exactPath: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    appendIcon: {
      type: String,
      default: "",
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    menus: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.menus-btn {
  margin: 0;
  height: 100%;
  font-size: 1.125rem;
  background: #fff;
  color: #4457ff;
  font-weight: bold;
  border-radius: 8px 8px 0 0;
  padding: 6px 32px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
</style>
