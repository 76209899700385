<template>
  <VRow class="ma-0 pa-0">
    <VCol
      v-if="label && !useMaterials"
      cols="12"
      align="start"
      class="ma-0 pa-0"
    >
      <p class="font-weight-medium mb-0 text--primary--theme">
        {{ label }}
      </p>
    </VCol>
    <VCol
      cols="12"
      :class="{
        'pt-1': !useMaterials,
        'margin-bottom': useMaterials ? '0' : '-22)x',
      }"
      class="ma-0 pa-0"
    >
      <vue-tel-input-vuetify
        v-bind="$attrs"
        :placeholder="
          $attrs.placeholder ? $attrs.placeholder : '06 66 66 66 66'
        "
        :label="useMaterials ? label : null"
        v-on="$listeners"
      />
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: "DresskareTel",
  props: {
    label: {
      type: String,
      default: "",
    },
    useMaterials: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
