<template>
  <VCard :outlined="outlined" :style="style">
    <VCardTitle v-if="header" class="display-1 font-weight-bold pa-0 pl-2 mb-4">
      <div class="base-card__title-container noselect">
        <div v-if="icon" class="header-icon">
          <svgicon
            v-if="!useVuetifyIcon"
            :name="icon"
            color="#00669E"
            height="20"
            width="20"
          />

          <VIcon v-else color="primary" size="20">
            {{ icon }}
          </VIcon>
        </div>

        <slot name="header-title"> Titre par défaut </slot>
      </div>

      <slot name="header-right" />
    </VCardTitle>

    <VDivider v-if="withDivider" />

    <VCardActions
      v-if="$slots['subheader']"
      class="base-card__subheader px-0 py-2"
    >
      <slot name="subheader" />
    </VCardActions>

    <VDivider v-if="$slots['subheader'] && withDivider" />

    <div
      v-if="$slots['content']"
      class="base-card__content pa-0 py-2"
      :style="{
        'min-height': minHeight,
        height: contentHeight,
      }"
    >
      <slot name="content"> Contenu par défaut </slot>
    </div>

    <div v-if="$slots['mock']" class="base-card__mock">
      <slot name="mock" />
    </div>

    <div v-if="$slots['footer']" class="base-card__footer">
      <slot name="footer" />
    </div>

    <div v-if="$slots['footer-pagination']" class="base-card__footer">
      <div class="pagination">
        <slot name="footer-pagination" />
      </div>
    </div>
  </VCard>
</template>

<script>
export default {
  name: "DresskareBaseContent",
  props: {
    icon: {
      type: String,
      default: "",
    },
    itemMaxHeight: {
      type: String,
      default: "",
    },
    useVuetifyIcon: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: String,
      default: null,
    },
    header: {
      type: Boolean,
      default: true,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    minHeightSubHeader: {
      type: String,
      default: "40px",
    },
    withDivider: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: "transparent",
    },
    outlined: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      style: {
        "max-height": this.itemMaxHeight,
        height: this.fillHeight ? "100%" : null,
        "background-color": this.backgroundColor,
      },
    };
  },
  computed: {
    contentHeight() {
      const headerSize = 60;
      const heightSubheader = parseInt(this.minHeightSubHeader.slice(0, -2));
      if (!this.fillHeight) {
        return this.minHeight;
      }
      if (this.$slots["subheader"] && this.header) {
        return `calc(100% - ${headerSize + heightSubheader}px)`;
      }
      if (this.header) {
        return `calc(100% - ${headerSize}px)`;
      }
      if (this.$slots["subheader"]) {
        return `calc(100% - ${heightSubheader}px)`;
      }
      return "100%";
    },
  },
};
</script>

<style lang="scss">
.base-card__title-container {
  color: #00669e;
  flex: 1 auto;
  display: flex;
  align-items: center;

  .header-icon {
    margin-right: 1rem;
  }
}

.base-card__content {
  overflow-y: auto;
  padding: 1rem;
  align-items: center;

  .base-button-empty__container {
    .btn {
      width: 121px;
      height: 33px;
      border-radius: 7px;
    }
  }
}

.base-card__mock {
  padding: 0;
  align-items: center;

  .base-button-empty__container {
    .btn {
      width: 121px;
      height: 33px;
      border-radius: 7px;
    }
  }
}

.base-card__link {
  &:hover {
    text-decoration: none;
  }
}

.base-card__footer {
  margin-bottom: 10px;
}
</style>
