import store from "@/store";
import { computed } from "vue";
import Vendor from "@/models/Vendor";
import Customer from "@/models/Customer";

export const GROUP_MAPPING = {
  Developer: "Developer",
  Vendor: "Vendor",
  Customer: "Customer",
  Company: "Company",
  Admin: "Admin",
};

export default function () {
  const oidcUser = store.getters["oidc/oidcUser"];

  const userGroup = computed(() => {
    if (!oidcUser) {
      return null;
    }
    if (oidcUser.groups.includes(GROUP_MAPPING.Developer)) {
      return GROUP_MAPPING.Developer;
    }

    if (oidcUser.groups.includes(GROUP_MAPPING.Admin)) {
      return GROUP_MAPPING.Admin;
    }
    if (oidcUser.groups.includes(GROUP_MAPPING.Vendor)) {
      return GROUP_MAPPING.Vendor;
    }

    if (oidcUser.groups.includes(GROUP_MAPPING.Customer)) {
      return GROUP_MAPPING.Customer;
    }

    return null;
  });

  const isDeveloper = computed(() => {
    return userGroup.value === GROUP_MAPPING.Developer;
  });

  const isCompanyAdmin = computed(() => {
    return oidcUser.groups.includes(GROUP_MAPPING.Company);
  });

  const isCompanyAdminOrUp = computed(() => {
    return isCompanyAdmin.value || isDeveloper.value || isAdmin.value;
  });

  const isAdmin = computed(() => {
    return [GROUP_MAPPING.Developer, GROUP_MAPPING.Admin].includes(
      userGroup.value
    );
  });

  const isVendor = computed(() => {
    return userGroup.value === GROUP_MAPPING.Vendor;
  });
  const isVendorOrUp = computed(() => {
    return isVendor.value || isCompanyAdminOrUp.value;
  });
  const isCustomer = computed(() => {
    return userGroup.value === GROUP_MAPPING.Customer;
  });

  const currentCustomer = computed(() => {
    if (!oidcUser) {
      return null;
    }
    return Customer.query()
      .withAll()
      .where("keycloakUserUuid", oidcUser.sub)
      .first();
  });

  const currentUser = computed(() => {
    if (isVendor.value || isAdmin.value) {
      return Vendor.query()
        .withAll()
        .where("keycloakUserUuid", oidcUser.sub)
        .first();
    }
    if (isCustomer.value) {
      return currentCustomer.value;
    }
    return null;
  });

  return {
    userGroup,
    isDeveloper,
    isVendor,
    isVendorOrUp,
    isCustomer,
    isCompanyAdmin,
    isCompanyAdminOrUp,
    isAdmin,
    currentUser,
    currentCustomer,
  };
}
