import Vue from "vue";
import VueRouter from "vue-router";

import DresskareChildrens from "./dresskare/dresskareChildrens";
import newDepositChildrens from "./new-deposit/newDepositChildrens";

import store from "@/store";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";

import dresskareRouterMiddleware from "./dresskareRouterMiddleware";

const OidcCallback = () =>
  import(/* webpackChunkName: "OidcCallback" */ "@/components/OidcCallback");
const Dresskare = () =>
  import(/* webpackChunkName: "Dresskare" */ "@/components/Dresskare");
const UnrecognizedUser = () =>
  import(
    /* webpackChunkName: "UnrecognizedUser" */ "@/components/UnrecognizedUser"
  );

const NoGroup = () =>
  import(/* webpackChunkName: "NoGroup" */ "@/components/NoGroup");

const NewDepositValidation = () =>
  import(
    /* webpackChunkName: "NewDepositValidation" */ "@/components/NewDepositValidation"
  );

const SubscriptionConfirmed = () =>
  import(
    /* webpackChunkName: "SubscriptionConfirmed" */ "@/components/SubscriptionConfirmed"
  );

const NewVendor = () =>
  import(/* webpackChunkName: "NewVendor" */ "@/components/NewVendor");

const NewVendorValidation = () =>
  import(
    /* webpackChunkName: "NewVendorValidation" */ "@/components/NewVendorValidation"
  );
const NewDeposit = () =>
  import(/* webpackChunkName: "NewDeposit" */ "@/components/NewDeposit");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Dresskare,
    children: DresskareChildrens,
  },
  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true,
    },
  },
  {
    path: "/unrecognized-user",
    name: "UnrecognizedUser",
    component: UnrecognizedUser,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/no-group",
    name: "NoGroup",
    component: NoGroup,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/nouveau-depot-seconde-main",
    alias: ["/new-deposit"],
    component: NewDeposit,
    children: newDepositChildrens,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/nouveau-depot-seconde-main-validation",
    alias: ["/new-deposit-validation"],
    name: "NewDepositValidation",
    component: NewDepositValidation,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/subscription-confirmed",
    name: "SubscriptionConfirmed",
    alias: ["//subscription-confirmed"],
    component: SubscriptionConfirmed,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/nouveau-vendeur-seconde-main",
    name: "NewVendor",
    component: NewVendor,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/nouveau-vendeur-seconde-main-validation",
    alias: ["/new-vendor-validation"],
    name: "NewVendorValidation",
    component: NewVendorValidation,
    meta: {
      isPublic: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidc"));
router.beforeEach(dresskareRouterMiddleware(store));

const DEFAULT_TITLE = "DressKare";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
