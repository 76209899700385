<template>
  <VDialog
    :content-class="`ma-0 dresskare-side-panel dresskare-side-panel-${side}`"
    :width="width"
    :value="value"
    hide-overlay
    :persistent="persistent"
    @input="$emit('input', $event)"
  >
    <VCard class="dresskare-side-panel-content">
      <VRow no-gutters>
        <VCol cols="10">
          <h2 v-if="title" class="pa-4">{{ title }}</h2>
        </VCol>
        <VCol cols="2" class="text-right pa-1">
          <VIcon @click="$emit('input', false)">mdi-close</VIcon>
        </VCol>
      </VRow>
      <slot />
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: "DresskareBaseSidePanel",
  props: {
    title: {
      type: String,
      default: "",
    },
    side: {
      type: String,
      enum: ["right", "left"],
      default: "right",
    },
    value: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [String, Number],
      default: "300px",
    },
  },
};
</script>

<style lang="scss">
.dresskare-side-panel {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100vh;
  max-height: 100% !important;
}
.dresskare-side-panel-right {
  right: 0;
}
.dresskare-side-panel-left {
  left: 0;
}
.dresskare-side-panel-content {
  position: relative;
  height: 100%;
  overflow-y: auto;
}
</style>
