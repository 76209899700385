<template>
  <VNavigationDrawer
    :value="value"
    class="sidebar__drawer"
    :mini-variant.sync="sidebarMini"
    v-bind="$attrs"
    app
    @input="$emit('input', $event)"
  >
    <VList dense class="mt-1">
      <VListItem
        class="d-flex justify-start mb-2"
        :ripple="false"
        @click="sidebarMini = !sidebarMini"
      >
        <VListItemIcon class="mr-4">
          <VIcon color="primary"> mdi-menu </VIcon>
        </VListItemIcon>
        <VListItemTitle
          class="text--primary--theme font-weight-black text-uppercase subtitle-1"
        >
          Dresskare
        </VListItemTitle>
      </VListItem>
      <div v-for="item in displayedItems" :key="item.routeName">
        <VListItem
          v-if="!item.childrens"
          class="d-flex justify-start"
          :exact="item.exact"
          :ripple="false"
          @click="goToPage(item)"
        >
          <VListItemIcon class="mr-2">
            <VIcon :color="getItemColor(item)" size="28">
              {{ item.icon }}
            </VIcon>
          </VListItemIcon>
          <VListItemContent>
            <VListItemTitle class="text--primary--theme title-font">
              {{ item.textLabel }}
            </VListItemTitle>
          </VListItemContent>
        </VListItem>
        <VListGroup v-else :value="true">
          <template #activator>
            <VListItemIcon class="mr-2">
              <VIcon :color="getItemColor(item)" size="28">
                {{ item.icon }}
              </VIcon>
            </VListItemIcon>
            <VListItemContent>
              <VListItemTitle>{{ item.textLabel }}</VListItemTitle>
            </VListItemContent>
          </template>

          <VListItem
            v-for="subItem in item.childrens"
            :key="subItem.routeName"
            class="d-flex justify-start pl-8"
            :to="{ name: subItem.routeName }"
            :exact="subItem.exact"
            :ripple="false"
          >
            <VListItemIcon class="mr-2">
              <VIcon :color="getItemColor(subItem)" size="28">
                {{ subItem.icon }}
              </VIcon>
            </VListItemIcon>
            <VListItemContent>
              <VListItemTitle class="text--primary--theme title-font">
                {{ subItem.textLabel }}
              </VListItemTitle>
            </VListItemContent>
          </VListItem>
        </VListGroup>
      </div>
    </VList>

    <template #append>
      <slot name="append-before-divider"></slot>
      <VDivider />
      <VRow v-if="!sidebarMini" class="" no-gutters>
        <h4 class="px-4 py-4">{{ $t("account") }}</h4>
      </VRow>
      <VList dense class="">
        <VListItem
          class="d-flex justify-center"
          :ripple="false"
          @click="contactSupport"
        >
          <VListItemAvatar>
            <VIcon color="primary" size="28"> mdi-face-agent </VIcon>
          </VListItemAvatar>
          <VListItemContent>
            <VListItemTitle class="text--primary--theme title-font">
              {{ $t("contactSupport") }}
            </VListItemTitle>
          </VListItemContent>
        </VListItem>

        <VListItem
          class="d-flex justify-center"
          :ripple="false"
          @click="signOutOidc"
        >
          <VListItemAvatar>
            <VIcon color="primary" size="28"> mdi-exit-to-app </VIcon>
          </VListItemAvatar>
          <VListItemContent>
            <VListItemTitle class="text--primary--theme title-font">
              {{ $t("logout") }}
            </VListItemTitle>
          </VListItemContent>
        </VListItem>

        <VRow class="mt-4 ml-4" no-gutters> </VRow>

        <VListItem
          id="item-profile"
          class="my-4 d-flex justify-center"
          :ripple="false"
          @click="goToProfile"
        >
          <VListItemAvatar>
            <DresskareBaseInitials
              :first-name="currentUser.firstName"
              :last-name="currentUser.lastName"
              :profil-picture="currentUser.profilPicture"
              size="28"
              font-size="11px"
              :color="avatarColor"
            />
          </VListItemAvatar>
          <VListItemContent>
            <VListItemTitle
              class="text--primary--theme"
              style="font-size: 10px"
            >
              {{ currentUser.firstName }} {{ currentUser.lastName }}
            </VListItemTitle>
            <VListItemSubtitle style="font-size: 10px">
              {{ $t("myProfil") }}
            </VListItemSubtitle>
          </VListItemContent>
        </VListItem>
      </VList>
      <VRow class="mt-0 mb-2 ml-4 text-center" no-gutters justify="center">
        <VCol>
          <a
            class="text-caption"
            href="https://www.dresskare.com/conditions-generales-solution-plateforme-seconde-main"
            target="_blank"
          >
            Conditions générales
          </a>
        </VCol>
      </VRow>
    </template>
  </VNavigationDrawer>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DresskareSideBar",
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    navItems: {
      type: Array,
      default: () => [],
    },
    currentUser: {
      type: Object,
      required: true,
    },
    editProfileUrl: {
      type: [String, Object],
      required: true,
    },
    avatarColor: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
      isSmallScreen: false,
      // sidebarMini: this.$vuetify.breakpoint.mobile,
      sidebarMini: false,
    };
  },
  computed: {
    displayedItems() {
      return this.navItems.filter((item) => {
        return item.displayLink;
      });
    },
  },
  methods: {
    ...mapActions("oidc", ["signOutOidc"]),
    getItemColor(item) {
      return item.color ?? "primary";
    },
    contactSupport() {
      window.open("mailto:nadege@dresskare.com");
    },
    goToProfile() {
      if (this.$route.name !== this.editProfileUrl.name) {
        this.$router.push(this.editProfileUrl);
      }
    },
    goToPage(item) {
      if (item.external) {
        window.open(item.url, item.newTab ? "_blank" : "_self");
      }
      if (this.$route.name !== item.routeName) {
        this.$router.push({ name: item.routeName });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-font {
  font-size: 15px !important;
}
.sidebar__layout {
  flex: none;
  width: 60px;
}
.sidebar__drawer {
  height: 100dvh !important;
  .v-list--nav {
    padding: 0;
  }

  .v-list {
    .v-list-item {
      padding: 5px 15px;
      height: 50px;
      &.v-list-item--active {
        background: $light-blue-2;
      }
      &:hover {
        background: $light-blue-1;
      }
      .v-list-item__icon {
        margin-right: 20px;
      }
    }
  }

  &:not(.v-navigation-drawer--mini-variant) {
    #item-profile {
      border-radius: 20px;
      border: 1px solid $light-blue-2;
      padding: 5px 10px 5px 5px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &.v-navigation-drawer--mini-variant {
    #item-profile {
      padding: 0;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "contactSupport": "Support",
    "logout": "Logout",
    "myProfil": "My profil",
    "account": "Account"
  },
  "fr": {
    "contactSupport": "Support",
    "logout": "Déconnexion",
    "myProfil": "Mon profil",
    "account": "Compte"
  }
}
</i18n>
