// @generated by protoc-gen-connect-es v1.3.0 with parameter "target=js+dts"
// @generated from file dresskare_back/invoice.proto (package dresskareback.invoice, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, MethodKind } from "@bufbuild/protobuf";
import { GenerateInvoicesRequest, InvoiceListRequest, InvoiceListResponse, InvoiceResponse, InvoiceRetrieveRequest, InvoiceUpdateInvoiceStatusRequest, InvoiceWithExtraDataListResponse } from "./invoice_pb.js";

/**
 * @generated from service dresskareback.invoice.InvoiceController
 */
export const InvoiceController = {
  typeName: "dresskareback.invoice.InvoiceController",
  methods: {
    /**
     * @generated from rpc dresskareback.invoice.InvoiceController.ExportInvoices
     */
    exportInvoices: {
      name: "ExportInvoices",
      I: Empty,
      O: InvoiceWithExtraDataListResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc dresskareback.invoice.InvoiceController.GenerateInvoices
     */
    generateInvoices: {
      name: "GenerateInvoices",
      I: GenerateInvoicesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.invoice.InvoiceController.List
     */
    list: {
      name: "List",
      I: InvoiceListRequest,
      O: InvoiceListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.invoice.InvoiceController.Retrieve
     */
    retrieve: {
      name: "Retrieve",
      I: InvoiceRetrieveRequest,
      O: InvoiceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc dresskareback.invoice.InvoiceController.UpdateInvoiceStatus
     */
    updateInvoiceStatus: {
      name: "UpdateInvoiceStatus",
      I: InvoiceUpdateInvoiceStatusRequest,
      O: InvoiceResponse,
      kind: MethodKind.Unary,
    },
  }
};

