<template>
  <DresskareBaseContent v-bind="$attrs">
    <template #header-title>
      <slot name="header-title">
        {{ title }}
      </slot>
    </template>
    <template #header-right>
      <slot name="header-right">
        <div
          v-if="navLinkHeaderRight"
          class="d-flex pt-2 pr-2 align-self-start"
        >
          <DresskareBaseNavigationLink
            v-for="tab in Object.keys(items)"
            :key="tab"
            :display-link="isDisplayed(tab)"
            :link-class="navLinkClass"
            :icon="navLinkIcon"
            :exact="items[tab].exact"
            :text="items[tab].title"
            :route-name="items[tab].routeName"
            :params="items[tab].params"
            @click.native="goToRoute(tab)"
          />
        </div>
      </slot>
    </template>
    <template #subheader>
      <slot name="subheader">
        <div v-if="navLinkSubHeader" class="d-flex">
          <DresskareBaseNavigationLink
            v-for="tab in Object.keys(items)"
            :key="tab"
            :display-link="isDisplayed(tab)"
            :link-class="navLinkClass"
            :icon="navLinkIcon"
            :exact="items[tab].exact"
            :text="items[tab].title"
            :route-name="items[tab].routeName"
            :params="items[tab].params"
            @click.native="goToRoute(tab)"
          />
        </div>
      </slot>
    </template>
    <template #content>
      <slot name="content">
        <VProgressCircular v-if="loading" indeterminate color="primary" />
        <RouterView
          v-else-if="routerViews"
          :value="value"
          @input="$emit('input', $event)"
        />
        <component
          :is="items[currentItem].component"
          v-else
          :value="value"
          :in-modal="true"
          v-bind="defaultComponentProps[currentItem]"
          @input="$emit('input', $event)"
        />
      </slot>
    </template>
  </DresskareBaseContent>
</template>

<script>
import DresskareBaseContent from "./DresskareBaseContent.vue";
import DresskareBaseNavigationLink from "./DresskareBaseNavigationLink.vue";

export default {
  name: "DresskareBaseNavigationCard",
  components: { DresskareBaseContent, DresskareBaseNavigationLink },
  props: {
    value: {
      type: null,
      default: null,
    },
    // Ex: { Affaires: { title: "Affaires", component: AffaireTab, display: true, params: {type: "aso"} } }
    items: {
      type: Object,
      required: true,
    },
    routerViews: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    defaultTab: {
      type: String,
      default: "",
    },
    forceCurrentTabTo: {
      type: String,
      default: null,
    },
    defaultComponentProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    navLinkSubHeader: {
      type: Boolean,
      default: false,
    },
    navLinkHeaderRight: {
      type: Boolean,
      default: false,
    },
    navLinkClass: {
      type: String,
      default: "tab-link",
    },
    navLinkIcon: {
      type: String,
      default: "",
    },
    navLinkExact: {
      type: Boolean,
      default: true,
    },
    // props for Loader
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTab: null,
    };
  },
  computed: {
    currentItem() {
      if (this.forceCurrentTabTo !== null) {
        // this is used when there is only one `routeName` with a route parameter in it
        return this.forceCurrentTabTo;
      }
      // Return current active tab based on URL
      if (this.routerViews) {
        const keys = Object.keys(this.items).filter((key) =>
          this.$route.name.startsWith(this.items[key].routeName)
        );
        if (keys.length > 0) {
          return keys.find(
            (key) => this.$route.name == this.items[key].routeName
          );
        }
        return keys[0];
        // Return current tab based on default tab or last clicked tab
      } else {
        return this.currentTab ? this.currentTab : this.defaultTab;
      }
    },
  },
  methods: {
    isDisplayed(tab) {
      return (
        typeof this.items[tab].display === "undefined" ||
        this.items[tab].display
      );
    },
    goToRoute(tab) {
      this.currentTab = tab;
    },
    routeParams(tab) {
      if (this.routerViews && this.items[tab].params) {
        return this.items[tab].params;
      }
      return {};
    },
  },
};
</script>

<style lang="scss">
.tab-link {
  height: 40px;
  min-width: 96px;
  justify-content: center;
  font-size: 1rem;
  font-weight: normal;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333333 !important;
  padding: 0 16px;
  border-bottom: 3px solid transparent;
  &:hover,
  &.active {
    color: #333333;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 3px solid #fcb902;
  }
}
</style>
