import { Model } from "@vuex-orm/core";
import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import { ProductSizeController } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_connect";
const productSizeClient = useDresskareGrpcClient(ProductSizeController);

export default class ProductSize extends Model {
  static entity = "productSize";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      vintedId: this.number(),
      title: this.string(),
      sizeGroups: this.attr([]).nullable(),
      order: this.number(),
      groupDescriptions: this.attr([]).nullable(),
      skipPriceLimit: this.boolean(),
    };
  }

  /**
   * Get product sizes from back. It expects a group_size uuid as filter even if not required to match the current category
   * @param {*} filters
   * @returns
   */
  static getProductSizes = async (filters) => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }
      metadata.pagination = JSON.stringify({ page_size: 700 });

      const response = await productSizeClient.list({}, { headers: metadata });
      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static getProductSizeByUuid = async (sizeUuid) => {
    const sizeFromStore = this.find(sizeUuid);

    // INFO - AM - 09/10/2022 - When retrieving deposit we get vendor first_name and last_name so the vendor exist in store but not fully
    if (sizeFromStore) {
      return sizeFromStore;
    } else {
      try {
        const response = await productSizeClient.retrieve(
          { uuid: sizeUuid },
          {}
        );

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.error("error:", error);
        return null;
      }
    }
  };
}
