import Vendor from "@/models/Vendor";
import Customer from "@/models/Customer";

const checkCustomPermission = (to, from, next, oidcUserGroups) => {
  if (oidcUserGroups.includes("Developer") || !to.meta.groupsAuthorized) {
    return next();
  }

  if (
    oidcUserGroups.some((group) => to.meta.groupsAuthorized.includes(group))
  ) {
    return next();
  } else {
    return next({ name: "Dashboard" });
  }
};

export default (store) => {
  return async (to, from, next) => {
    if (to.meta.isPublic) {
      return next();
    }

    const oidcUser = store.getters["oidc/oidcUser"];

    try {
      if (oidcUser.groups.includes("Developer")) {
        return checkCustomPermission(to, from, next, oidcUser.groups);
      }

      if (oidcUser.groups.includes("Vendor")) {
        const result = await Vendor.getVendorByKeycloakUuid(oidcUser.sub);
        if (!result) {
          return next({ name: "UnrecognizedUser" });
        }
        return checkCustomPermission(to, from, next, oidcUser.groups);
      }

      if (oidcUser.groups.includes("Customer")) {
        const result = await Customer.getCustomerByKeycloakUuid(oidcUser.sub);
        if (!result) {
          return next({ name: "UnrecognizedUser" });
        }
        return checkCustomPermission(to, from, next, oidcUser.groups);
      }
    } catch (err) {
      console.error(err);
      return next({ name: "UnrecognizedUser" });
    }

    return next({ name: "NoGroup" });

    // If user is loaded that mean we already fetched user without error. No need to do it again
    // if (store.getters["user/isLoaded"]) {
    //   return next();
    // }

    // // We load user from rapsotec back and if we get 401 that mean user is a bimdata user not recognized by us
    // try {
    //   await store.dispatch("user/fetchRapsotecUser");
    //   return next();
    // } catch (err) {
    //   return next({ name: "unrecognized-user" });
    // }
  };
};
