<template>
  <VRow align="center" class="my-0">
    <VCol
      v-if="type === 'date' || type === 'datetime' || type === 'month'"
      class="pl-3 py-0"
    >
      <VMenu
        :close-on-content-click="closeOnContentClick"
        transition="scale-transition"
        offset-y
        :min-width="minWidth.datePicker"
        :nudge-right="nudgeRight.datePicker"
        :max-width="maxWidth.datePicker"
      >
        <template #activator="{ on, attrs }">
          <VRow align="center">
            <VCol>
              <DresskareTextField
                :value="displayDate"
                :label="dateLabel"
                :prepend-inner-icon="datePrependIcon"
                readonly
                :use-materials="useMaterials"
                v-bind="attrs"
                :rules="fieldsRules.datePicker"
                :outlined="outlined"
                :hide-details="hideDetails"
                :dense="dense"
                :clearable="clearable"
                @click:clear="clearData"
                v-on="on"
              />
            </VCol>
          </VRow>
        </template>
        <VDatePicker
          v-if="range"
          v-model="rangeDate"
          class="dateInput"
          :locale="locale"
          v-bind="$attrs"
          :min="minDate"
          :max="maxDate"
          :range="range"
          no-title
          :type="type === 'datetime' ? 'date' : type"
          :allowed-dates="allowedDates"
          @click:date="triggerDatePicked"
        />
        <VDatePicker
          v-else
          v-model="date"
          class="dateInput"
          :locale="locale"
          v-bind="$attrs"
          :min="minDate"
          :max="maxDate"
          no-title
          :type="type === 'datetime' ? 'date' : type"
          :allowed-dates="allowedDates"
          @click:date="triggerDatePicked"
        />
      </VMenu>
    </VCol>
    <VCol
      v-if="type === 'time' || (type === 'datetime' && !forceHour)"
      class="pl-3 py-0"
    >
      <VMenu
        ref="menu"
        v-model="menuDateTime"
        :close-on-content-click="closeOnContentClick"
        :nudge-right="nudgeRight.timePicker"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        :min-width="minWidth.timePicker"
        :max-width="maxWidth.timePicker"
      >
        <template #activator="{ on, attrs }">
          <DresskareTextField
            v-model="time"
            :label="timeLabel"
            :prepend-inner-icon="timePrependIcon"
            readonly
            v-bind="attrs"
            :rules="fieldsRules.timePicker"
            v-on="on"
          ></DresskareTextField>
        </template>
        <VTimePicker
          v-if="menuDateTime"
          v-model="time"
          class="timeInput"
          full-width
          :locale="locale"
          format="24hr"
          @click:minute="$refs.menu.save(time)"
        ></VTimePicker>
      </VMenu>
    </VCol>
  </VRow>
</template>

<script>
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);
require("dayjs/locale/fr");

export default {
  name: "DresskareBaseInputDate",
  props: {
    value: {
      type: [Object, String, Array],
      default: null,
    },
    closeOnContentClick: {
      type: Boolean,
      default: false,
    },
    defaultToday: {
      type: Boolean,
      default: false,
    },
    dateLabel: {
      type: String,
      default: "Date",
    },
    timeLabel: {
      type: String,
      default: "Heure",
    },
    type: {
      type: String,
      default: "date",
    },
    displayDateFormat: {
      type: String,
      default: "DD/MM/YYYY",
    },
    minDate: {
      // String date as expected by VDatePicker: YYYY-MM-DD
      type: String,
      default: null,
      validator: (value) => typeof Date.parse(value) === "number",
    },
    maxDate: {
      type: String,
      // String date as expected by VDatepicker: YYYY-MM-DD
      default: null,
    },
    format: {
      type: String,
      default: "YYYY-MM-DD",
    },
    dateTimeFormat: {
      type: String,
      default: "YYYY-MM-DD HH:mm",
    },
    datePrependIcon: {
      type: String,
      default: "mdi-calendar",
    },
    timePrependIcon: {
      type: String,
      default: "mdi-clock-time-four-outline",
    },
    locale: {
      type: String,
      default: "fr-FR",
    },
    timezone: {
      type: String,
      default: "Europe/Paris",
    },

    fieldsRules: {
      type: Object,
      default: () => ({
        datePicker: [true],
        timePicker: [true],
      }),
    },

    minWidth: {
      type: Object,
      default: () => ({
        datePicker: "290px",
        timePicker: "290px",
      }),
    },

    nudgeRight: {
      type: Object,
      default: () => ({
        datePicker: 0,
        timePicker: 0,
      }),
    },

    maxWidth: {
      type: Object,
      default: () => ({
        datePicker: "auto",
        timePicker: "auto",
      }),
    },

    clearable: {
      type: Boolean,
      default: false,
    },
    forceHour: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    getInUtc: {
      type: Boolean,
      default: true,
    },
    allowedDates: {
      type: Function,
      default: null,
    },
    useMaterials: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      date: null,
      rangeDate: [],
      time: null,
      vuetifyDateFormat: "YYYY-MM-DD",
      vuetifyMonthFormat: "YYYY-MM",
      vuetifyTimeFormat: "HH:mm",
      menuDateTime: false,
    };
  },

  computed: {
    valueFormated() {
      if (this.value === null) {
        if (this.defaultToday) {
          if (this.range) {
            return [dayjs()];
          }
          return dayjs();
        }
        return this.value;
      }
      if (typeof this.value === "string") {
        if (!this.value) {
          if (this.defaultToday) {
            return dayjs();
          } else {
            return null;
          }
        }
        let date = dayjs(this.value, this.format);
        if (!date.isValid()) {
          date = dayjs(this.value);
        }
        return date;
      }
      if (Array.isArray(this.value) && this.range) {
        return this.value.map(dayjs);
      }
      return dayjs(this.value);
    },
    displayDate() {
      if (this.range) {
        return this.rangeDate
          .map((date) => dayjs(date).format(this.displayDateFormat))
          .join(" ~ ");
      }
      if (this.type === "month") {
        return this.date ? dayjs(this.date).locale("fr").format("MMMM") : "";
      }
      return this.date ? dayjs(this.date).format(this.displayDateFormat) : "";
    },
  },

  watch: {
    date(date) {
      if (date === null) {
        return;
      }
      this.triggerInput();
    },
    // rangeDate(dates) {
    //   if (!dates) {
    //     return;
    //   }
    //   this.triggerInput();
    // },
    time(time) {
      if (time === null) {
        return;
      }
      this.triggerInput();
    },
    valueFormated() {
      this.updateData();
    },
    value(value) {
      if (!value) {
        this.date = null;
        this.time = null;
        this.rangeDate = [];
      }
    },
  },

  created() {
    this.updateData();
  },

  methods: {
    triggerDatePicked() {
      this.triggerInput();
      if (this.range) {
        this.$emit("date-picked", this.rangeDate);
      } else {
        this.$emit("date-picked", this.date);
      }
    },

    localize(dateAsString, vuetifyDateFormat) {
      let date = dayjs(dateAsString, vuetifyDateFormat).tz(this.timezone);
      if (this.getInUtc && !this.range) {
        return date.utc();
      }
      return date;
    },

    triggerInput() {
      if (this.range) {
        let rangeDateToEmit = [];
        if (this.rangeDate.length > 0) {
          rangeDateToEmit.push(
            this.localize(this.rangeDate[0], this.vuetifyDateFormat)
          );
        }
        if (this.rangeDate.length > 1) {
          rangeDateToEmit.push(
            this.localize(this.rangeDate[1], this.vuetifyDateFormat)
              .hour(23)
              .minute(59)
          );
        }

        this.$emit(
          "input",
          rangeDateToEmit.map((date) => date.format(this.format)),
          rangeDateToEmit
        );
      } else if (this.type === "date") {
        this.$emit(
          "input",
          dayjs(this.date, this.vuetifyDateFormat).format(this.format),
          dayjs(this.date, this.vuetifyDateFormat)
        );
      } else if (this.type === "month") {
        this.$emit(
          "input",
          dayjs(this.date, this.vuetifyMonthFormat).format(this.format),
          dayjs(this.date, this.vuetifyMonthFormat)
        );
      } else if (this.type === "time") {
        this.$emit("input", this.time);
      } else if (this.type === "datetime" && this.date && this.time) {
        if (this.forceHour) {
          this.time = dayjs().format(" HH:mm");
        }
        const newDate = this.localize(
          this.date + this.time,
          this.dateTimeFormat
        );
        this.$emit("input", newDate.format(this.dateTimeFormat), newDate);
      }
    },

    updateData() {
      if (this.valueFormated === null) {
        return;
      }
      if (this.range) {
        this.rangeDate = this.valueFormated.map((date) =>
          date.format(this.vuetifyDateFormat)
        );
        return;
      }
      if (Object.keys(this.valueFormated).length > 0) {
        this.date = this.valueFormated.format(this.vuetifyDateFormat);
        this.time = this.valueFormated.format(this.vuetifyTimeFormat);
      }
    },
    clearData() {
      if (this.range) {
        this.$emit("input", [], []);
      } else {
        this.$emit("input", null, null);
      }
    },
  },
};
</script>
