<template>
  <VDialog v-model="displayModalLegalTerm" max-width="80%" persistent>
    <VCard class="pb-3 pt-5 px-12">
      <VCardTitle class="px-7 mt-3 text-h4 text-center">
        {{ $t("acceptLegalTerms") }}
      </VCardTitle>

      <VCardText class="mt-10" style="font-size: 18px">
        <p>
          {{ $t("legalTermText") }}
          <a
            href="https://www.dresskare.com/conditions-generales-solution-plateforme-seconde-main"
            target="_blank"
            >https://www.dresskare.com/conditions-generales-solution-plateforme-seconde-main</a
          >
        </p>
      </VCardText>

      <VCardActions class="mt-8">
        <VRow :justify="$vuetify.breakpoint.mobile ? 'center' : 'end'">
          <VCol class="flex-grow-0">
            <VBtn
              color="primary"
              depressed
              class="mr-5 px-6"
              @click="acceptLegalTerms"
            >
              {{ $t("accept") }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import dayjs from "dayjs";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "DresskareLegalTerms",
  props: {
    userLegalTermsAcceptedAt: {
      type: String,
      default: null,
    },
    legalTermsUpdatedAt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      displayModalLegalTerm: false,
      userLegalTermsAcceptedAtAsDate: null,
      legalTermsUpdatedAtAsDate: null,
    };
  },
  created() {
    // INFO - AM - 07/01/2024 - If user never accepted legal terms or legal terms updated since last time
    if (
      !this.userLegalTermsAcceptedAt ||
      dayjs(this.userLegalTermsAcceptedAt) <
        dayjs(this.legalTermsUpdatedAt, "DD/MM/YYYY HH:mm:ss")
    ) {
      this.displayModalLegalTerm = true;
    }
  },
  methods: {
    acceptLegalTerms() {
      this.$emit("acceptLegalTerms");
      this.displayModalLegalTerm = false;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "acceptLegalTerms": "Please accept legal terms",
    "legalTermText": "Find legal term here:",
    "accept": "Accept"
  },
  "fr": {
    "acceptLegalTerms": "Veuillez accepter nos conditions générales pour continuer ",
    "legalTermText": "Merci de prendre connaissance des nouvelles conditions générales : ",
    "accept": "Accepter"
  }
}
</i18n>
