const DressingListView = () =>
  import(
    /* webpackChunkName: "DressingListView" */ "@/components/dresskare/customer/DressingListView"
  );

const CustomerDressingDeposit = () =>
  import(
    /* webpackChunkName: "CustomerDressingDeposit" */ "@/components/dresskare/customer/CustomerDressingDeposit"
  );

const CustomerDressing = () =>
  import(
    /* webpackChunkName: "CustomerDressing" */ "@/components/dresskare/customer/CustomerDressing"
  );

const Informations = () =>
  import(
    /* webpackChunkName: "Informations" */ "@/components/dresskare/customer/Informations"
  );

const Invoices = () =>
  import(
    /* webpackChunkName: "Invoices" */ "@/components/dresskare/customer/Invoices"
  );

export default [
  {
    path: "",
    name: "CustomerInformations",
    component: Informations,
  },
  {
    path: "dressing",
    component: DressingListView,
    children: [
      {
        path: "",
        name: "CustomerDressing",
        component: CustomerDressing,
      },
      {
        path: ":depositUuid?",
        name: "CustomerDressingDeposit",
        component: CustomerDressingDeposit,
      },
    ],
  },
  {
    path: "invoices",
    name: "CustomerInvoices",
    component: Invoices,
  },
];
