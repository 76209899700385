<template>
  <div>
    <!-- <VRow>
      <h3 v-if="title">{{ title }}</h3>
    </VRow> -->
    <VRow>
      <VCol style="max-width: 200px">
        <!-- {{ value }} -->
        <h3 v-if="title">{{ title }}</h3>
      </VCol>
      <VDivider vertical />
      <VCol
        v-for="option in options"
        :key="realValue(option)"
        class="flex-grow-0"
      >
        <VChip
          color="primary"
          :outlined="!isValueMatching(option)"
          :filter="!isValueMatching(option)"
          @click="setValue(option)"
        >
          {{ realText(option) }}
        </VChip>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
export default {
  name: "DresskareBaseChipSelector",
  props: {
    value: {
      type: [String, Array],
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
    valueKey: {
      type: String,
      default: "",
    },
    textKey: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxNumber: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      originalValue: "",
    };
  },
  created() {
    this.originalValue = cloneDeep(this.value);
    this.cleanOptionsIfMultiple();
  },
  methods: {
    cleanOptionsIfMultiple() {
      if (this.multiple) {
        const valueFiltered = this.value.filter((v) => {
          return this.options.some((o) => this.realValue(o) === v);
        });
        this.$emit("input", valueFiltered);
      }
    },
    setValue(option) {
      // INFO - AM - 11/09/2022 - if we click on the same pin we put back the original value
      if (this.multiple) {
        if (this.value.includes(this.realValue(option))) {
          this.$emit(
            "input",
            this.value.filter((v) => v !== this.realValue(option))
          );
        } else if (!this.maxNumber || this.value.length < this.maxNumber) {
          this.$emit("input", [...this.value, this.realValue(option)]);
        } else {
          let copyValue = [...this.value];
          copyValue.splice(this.maxNumber - 1, 1);
          this.$emit("input", [...copyValue, this.realValue(option)]);
        }
      } else if (this.realValue(option) === this.value) {
        this.$emit("input", this.originalValue);
      } else {
        this.$emit("input", this.realValue(option));
      }
    },
    isValueMatching(option) {
      if (this.multiple) {
        return this.value.includes(this.realValue(option));
      }
      return this.value === this.realValue(option);
    },
    realValue(option) {
      return this.valueKey ? option[this.valueKey] : option;
    },
    realText(option) {
      return this.textKey ? option[this.textKey] : option;
    },
  },
};
</script>

<style>

</style>
