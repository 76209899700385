import XLSX from "xlsx";
import { saveAs } from "file-saver";

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

export const exportSheetToExcel = (
  sheetData,
  sheetHeaders,
  excelName = "dresskare-excel",
  fileName = "dresskare-export",
  options = { "!cols": [] }
) => {
  let wb = XLSX.utils.book_new();

  // INFO - AM - 25/03/2023 - Replace not accepted caractere in
  let sheetName = excelName.replace(/[*/?[\]\\]/g, "_");

  if (sheetName.length > 31) {
    sheetName = sheetName.substring(0, 31);
  }

  wb.Props = {
    Title: excelName,
    Subject: "Export",
    Author: "Dresskare",
    CreatedDate: new Date(),
  };

  wb.SheetNames.push(sheetName);

  var ws_data = [sheetHeaders, ...sheetData];
  var ws = XLSX.utils.aoa_to_sheet(ws_data);
  ws["!cols"] = options["!cols"];
  ws["!rows"] = [[], ...sheetData].map(() => ({ hpt: 25 }));
  wb.Sheets[sheetName] = ws;
  var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    `${fileName}.xlsx`
  );
};
