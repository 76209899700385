import { Model } from "@vuex-orm/core";
import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";

import { ProductImageController } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_connect";
import { ProductImageRequest } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_pb";
const productImageClient = useDresskareGrpcClient(ProductImageController);

export default class ProductImage extends Model {
  static entity = "productImage";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      product: this.attr(null),
      image: this.string(""),
      kind: this.string(null).nullable(),
      visionApiData: this.attr(null),
      order: this.number().nullable(),
    };
  }

  static getProductImages = async (filters) => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }
      const response = await productImageClient.list({}, { headers: metadata });

      let productImagesFromDB = response.results;

      if (!productImagesFromDB) {
        return null;
      }
      this.insertOrUpdate({ data: productImagesFromDB });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static create = async (form) => {
    try {
      const response = await productImageClient.create(
        ProductImageRequest.fromJson(form, { ignoreUnknownFields: true }),
        {}
      );

      let productImageFromDB = response;

      this.insertOrUpdate({ data: productImageFromDB });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static updateInDb = async (form) => {
    const response = await productImageClient.update(
      ProductImageRequest.fromJson(form, { ignoreUnknownFields: true }),
      {}
    );

    if (!response) {
      return null;
    }
    this.insertOrUpdate({ data: response });
    return response;
  };

  static destroy = async (productImageUuid) => {
    try {
      await productImageClient.destroy({ uuid: productImageUuid }, {});
      this.delete(productImageUuid);
    } catch (error) {
      console.error("error:", error);
    }
  };
}

export const PRODUCT_IMAGE_KIND = {
  FRONT: "FRONT",
  BACK: "BACK",
  LABEL: "LABEL",
  OTHER: "OTHER",
};
