import { Model } from "@vuex-orm/core";
import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import { DepositImageController } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_connect";
import { DepositImageRequest } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_pb";

const depositImageClient = useDresskareGrpcClient(DepositImageController);

export default class DepositImage extends Model {
  static entity = "depositImage";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      deposit: this.attr(null),
      image: this.string(""),
    };
  }

  static getDepositImages = async (filters) => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }

      const response = await depositImageClient.list({}, { headers: metadata });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };

  static create = async (form) => {
    try {
      const response = await depositImageClient.create(
        DepositImageRequest.fromJson(form, { ignoreUnknownFields: true }),
        {}
      );

      this.insertOrUpdate({ data: response });
      return response;
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };

  static updateInDb = async (form) => {
    const response = await depositImageClient.update(
      DepositImageRequest.fromJson(form, { ignoreUnknownFields: true }),
      {}
    );

    if (!response) {
      return null;
    }
    this.insertOrUpdate({ data: response });
    return response;
  };

  static destroy = async (depositImageUuid) => {
    try {
      await depositImageClient.destroy({ uuid: depositImageUuid }, {});
      this.delete(depositImageUuid);
    } catch (error) {
      console.log("error:", error);
    }
  };

  static assignDeposit = async (depositUuid, images) => {
    if (!images) {
      return;
    }
    let imagesRequests = images.map((image) => ({
      deposit: depositUuid,
      uuid: image.uuid,
    }));
    const response = await depositImageClient.assignDeposit(
      { results: imagesRequests },
      {}
    );

    this.insertOrUpdate({ data: response });
  };
}
