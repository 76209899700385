const VendorHiring = () =>
  import(
    /* webpackChunkName: "VendorHiring" */ "@/components/dresskare/stats-new/VendorHiring"
  );

export default [
  {
    path: "",
    name: "VendorHiring",
    component: VendorHiring,
  },
];
