import { Model } from "@vuex-orm/core";

export default class Subscription extends Model {
  static entity = "subscription";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      status: this.string(""),
      planInterval: this.string(""),
      planAmount: this.number(),
      type: this.string(""),
      vendor_id: this.attr(null),
      currentPeriodEnd: this.attr(),
      discountCouponPercentOff: this.number(0),
      discountCouponValid: this.boolean(false),
      stripeId: this.string(""),
    };
  }
}

export const SUBSCRIPTION_TYPE = {
  ESSENTIAL: "ESSENTIAL",
  PRO: "PRO",
  PREMIUM: "PREMIUM",
  COMMISSION: "COMMISSION",
  FORMATION: "FORMATION",
};

export const SUBSCRIPTION_STATUS = {
  INCOMPLETE: "incomplete",
  INCOMPLETE_EXPIRED: "incomplete_expired",
  TRIALING: "trialing",
  ACTIVE: "active",
  PAST_DUE: "past_due",
  CANCELED: "canceled",
  UNPAID: "unpaid",
};

export const SUBSCRIPTION_STATUS_COLOR = {
  [SUBSCRIPTION_STATUS.INCOMPLETE]: "orange--text",
  [SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED]: "orange--text",
  [SUBSCRIPTION_STATUS.TRIALING]: "yellow--text",
  [SUBSCRIPTION_STATUS.ACTIVE]: "green--text",
  [SUBSCRIPTION_STATUS.PAST_DUE]: "red--text",
  [SUBSCRIPTION_STATUS.CANCELED]: "red--text",
  [SUBSCRIPTION_STATUS.UNPAID]: "red--text",
};
