import * as components from "./components/index";

import utils from "./utils";
import notifications from "./store/modules/notifications";

export default {
  install: (app) => {
    Object.entries(components).forEach(([componentName, component]) => {
      app.component(componentName, component);
    });
  },
};

export { utils, notifications };
